import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Chart from 'react-google-charts'
import {BaseRequest} from '../../../services/Request'
import {
  DashboardPrintorder,
  DashboardPrintstatus,
  DashboardPrintTemplatestatus,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {isNullOrEmpty, moneyFormat, OrderStatus} from '../../../services/Utils'
import {t} from 'i18next'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardWrapper = () => {
  const intl = useIntl()
  const [loadinguser, setLoadinguser] = useState(false)
  const [showToday, setShowToday] = useState(true)
  const [showWeek, setShowWeek] = useState(true)
  const [showMonth, setShowMonth] = useState(false)
  const [showAlltime, setShowAlltime] = useState(false)
  const [data, setData] = useState<any>([])
  const [statdata, setStatData] = useState<any>([])
  const [tempStatData, setTempStatData] = useState<any>([])
  console.log(data)
  console.log(statdata)
  useEffect(() => {
    getUser()
    getStatus()
    getTemplateStatus()
  }, [])

  const getUser = () => {
    if (!loadinguser) {
      setLoadinguser(true)
    }
    var req = BaseRequest
    DashboardPrintorder(req, onUserSuccess, onFailed, 'POST')
  }
  const onFailed = (error: any) => {
    return toast.error(error)
  }
  const onUserSuccess = (response: any) => {
    setLoadinguser(false)
    setData(response)
  }

  const getTemplateStatus = () => {
    if (!loadinguser) {
      setLoadinguser(true)
    }
    var req = BaseRequest
    DashboardPrintTemplatestatus(req, onTemplateStatusSuccess, onFailed, 'POST')
  }

  const onTemplateStatusSuccess = (response: any) => {
    setLoadinguser(false)
    setTempStatData(response)
  }

  const getStatus = () => {
    if (!loadinguser) {
      setLoadinguser(true)
    }
    var req = BaseRequest
    DashboardPrintstatus(req, onStatusSuccess, onFailed, 'POST')
  }

  const onStatusSuccess = (response: any) => {
    setLoadinguser(false)
    setStatData(response)
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col' style={{rowGap: 50}}>
          <div className='bot-border'>
            <div className='card gap-4 flex flex-row' style={{padding: '15px 25px'}}>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowToday(!showToday)}
                    checked={showToday}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>today</span>
                </label>
              </div>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowWeek(!showWeek)}
                    checked={showWeek}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>week</span>
                </label>
              </div>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowMonth(!showMonth)}
                    checked={showMonth}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>month</span>
                </label>
              </div>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowAlltime(!showAlltime)}
                    checked={showAlltime}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>all time</span>
                </label>
              </div>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Захиалга</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <KTIcon className='fs-2x success' iconName='user-tick' />
                </div>
                {showToday ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {data?.lastday?.count}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                      {!isNullOrEmpty(data?.lastday?.niit) ? (
                        <span
                          className='text-primary fs-6'
                          style={{fontWeight: '500', marginLeft: '20px'}}
                        >
                          {moneyFormat(data?.lastday?.niit, 'MNT')}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showWeek ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {data?.lastweek?.count}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                      <span
                        className='text-primary fs-6'
                        style={{fontWeight: '500', marginLeft: '20px'}}
                      >
                        {moneyFormat(data?.lastweek?.niit, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Monthly</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showMonth ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        <div className='flex items-center'>
                          <span className='fs-3x' style={{fontWeight: '500'}}>
                            {data?.lastmonth?.count}
                          </span>
                        </div>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                      <span
                        className='text-primary fs-6'
                        style={{fontWeight: '500', marginLeft: '20px'}}
                      >
                        {moneyFormat(data?.lastmonth?.niit, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showAlltime ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {data?.all?.count}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                      <span
                        className='text-primary fs-6'
                        style={{fontWeight: '500', marginLeft: '20px'}}
                      >
                        {moneyFormat(data?.all?.niit, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Захиалга төлвөөр</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Өнөөдөр</span>
                  <KTIcon className='fs-2x success' iconName='user-tick' />
                </div>
                {showToday ? (
                  <div className='flex flex-col gap-1'>
                    {statdata?.lastday?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            backgroundColor: OrderStatus(el.status),
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '500',
                            color:"white"
                          }}
                        >
                          <span>{t(`common:magicnum.PrintStatus.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Энэ 7 хоногт</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showWeek ? (
                  <div className='flex flex-col gap-1'>
                    {statdata?.lastweek?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            backgroundColor: OrderStatus(el.status),
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '500',
                            color:"white"
                          }}
                        >
                          <span>{t(`common:magicnum.PrintStatus.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Энэ сард</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showMonth ? (
                  <div className='flex flex-col gap-1'>
                    {statdata?.lastmonth?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            backgroundColor: OrderStatus(el.status),
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '500',
                            color:"white"
                          }}
                        >
                          <span>{t(`common:magicnum.PrintStatus.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/ordercard'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Нийт</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showAlltime ? (
                  <div className='flex flex-col gap-1'>
                    {statdata?.all?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            backgroundColor: OrderStatus(el.status),
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '500',
                            color:"white"
                          }}
                        >
                          <span>{t(`common:magicnum.PrintStatus.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Загвар төлвөөр</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/templates'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Өнөөдөр</span>
                  <KTIcon className='fs-2x success' iconName='user-tick' />
                </div>
                {showToday ? (
                  <div className='flex flex-col gap-1'>
                    {tempStatData?.lastday?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            color: el.status === 0 ? '#f9ca24' : '#009432',
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '600',
                            border: `1px solid ${el.status === 0 ? '#f9ca24' : '#009432'}`,
                          }}
                        >
                          <span>{t(`common:magicnum.Status.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/templates'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Энэ 7 хоногт</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showWeek ? (
                  <div className='flex flex-col gap-1'>
                    {tempStatData?.lastweek?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            color: el.status === 0 ? '#f9ca24' : '#009432',
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '600',
                            border: `1px solid ${el.status === 0 ? '#f9ca24' : '#009432'}`,
                          }}
                        >
                          <span>{t(`common:magicnum.Status.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/templates'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Энэ сард</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showMonth ? (
                  <div className='flex flex-col gap-1'>
                    {tempStatData?.lastmonth?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            color: el.status === 0 ? '#f9ca24' : '#009432',
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '600',
                            border: `1px solid ${el.status === 0 ? '#f9ca24' : '#009432'}`,
                          }}
                        >
                          <span>{t(`common:magicnum.Status.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/templates'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Нийт</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showAlltime ? (
                  <div className='flex flex-col gap-1'>
                    {tempStatData?.all?.map((el: any) => {
                      return (
                        <div
                          className='flex justify-between items-center'
                          style={{
                            color: el.status === 0 ? '#f9ca24' : '#009432',
                            padding: '4px 20px',
                            borderRadius: '12px',
                            fontWeight: '600',
                            border: `1px solid ${el.status === 0 ? '#f9ca24' : '#009432'}`,
                          }}
                        >
                          <span>{t(`common:magicnum.Status.${el.status}`)}</span>
                          <span style={{fontSize: '16px'}}>{el.count}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
