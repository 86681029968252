import {magicnum} from './Request'
import config from './config.json'
import useCookie from './useCookie'
var CryptoJS = require('crypto-js')

export function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
  }
}

export function isNullOrEmpty(text) {
  var retVal = false
  if (text === '' || text === null || text === undefined) {
    retVal = true
  }
  return retVal
}

export function moneyFormat(amount, currency) {
  var temp = formatMoney(amount, 0, '.', ',')
  if (!isNullOrEmpty(currency)) {
    temp += currencySymbol(currency)
  }
  return temp
}

function currencySymbol(currency) {
  var symbol = '₮'
  switch (currency) {
    case 'MNT':
      symbol = '₮'
      break
    case 'USD':
      symbol = '$'
      break
    case 'N':
      symbol = ''
      break
    default:
      symbol = '%'
  }
  return symbol
}

export function getRealData(name) {
  var retVal = ''
  const getValue = name
  if (getValue !== null && getValue !== undefined && getValue !== '') {
    retVal = CryptoJS.AES.decrypt(getValue, config.engryptPass).toString(CryptoJS.enc.Utf8)
  }
  return retVal
}

export function IsPrivilege(privNo) {
  var retVal = false
  const data2 = localStorage.getItem('GAdata2')
  var stringData = getRealData(data2)
  if (stringData !== '') {
    const UserPrivs = JSON.parse(stringData)
    if (UserPrivs !== null && privNo !== undefined) {
      UserPrivs.map((i) => {
        if (i.PrivNo === privNo) {
          retVal = true
        }
      })
    }
  }
  return retVal
}

export function dateFormat(date) {
  var retVal = ''
  !isNullOrEmpty(date) ? (retVal = date.substring(0, 10)) : (retVal = '')
  return retVal.replace(/-/g, '.')
}

export function dateTimeFormat(date) {
  var retVal = ''
  !isNullOrEmpty(date)
    ? (retVal =
        date.substring(0, 10) +
        ' ' +
        (parseInt(date.substring(11, 13)) + 8) +
        date.substring(13, 16))
    : (retVal = '')
  return retVal.replace(/-/g, '.')
}

export function TimeFormat(date) {
  var retVal = ''
  !isNullOrEmpty(date) ? (retVal = date.substring(11, 13)) : (retVal = '')
  return retVal
}

export function addMonths(date, months) {
  date.setMonth(date.getMonth() + months)
  return formatDate(date)
}
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
export function SizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB'
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB'
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB'
  } else if (bytes > 1) {
    bytes = bytes + ' bytes'
  } else if (bytes == 1) {
    bytes = bytes + ' byte'
  } else {
    bytes = '0 bytes'
  }
  return bytes
}

export function customNumberRight(number) {
  return <div style={{textAlign: 'right'}}>{number}</div>
}

export function exportPng(svg, width, height) {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    let ctx = canvas.getContext('2d')

    // Set background to white
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, width, height)

    let xml = new XMLSerializer().serializeToString(svg)
    let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml)
    let img = new Image(width, height)

    img.onload = () => {
      ctx.drawImage(img, 0, 0)
      let imageData = canvas.toDataURL('image/png', 1.0)
      resolve(imageData)
    }

    img.onerror = () => reject()

    img.src = dataUrl
  })
}

export function dateTimeOnlyFormat(date) {
  var retVal = ''
  !isNullOrEmpty(date)
    ? (retVal =
        date.substring(0, 10) +
        ' ' +
        (parseInt(date.substring(11, 13)) + 8) +
        date.substring(13, 16))
    : (retVal = '')
  return retVal.replace(/-/g, '.')
}

export function LastAndFirstName(lastname, firstname) {
  var P = ''
  if (isNullOrEmpty(lastname) && isNullOrEmpty(firstname)) {
    P = ''
  } else if (!isNullOrEmpty(lastname)) {
    P = lastname.substring(0, 1) + '.' + firstname
  } else {
    P = firstname
  }
  return P
}

export function OrderStatus(cell) {
  switch (cell) {
    case 1:
      return '#bdc3c7'; 
    case 2:
      return '#3867d6'; //tsenher tod
    case 3:
      return '#2d98da'; // tsenher dund
    case 4:
      return '#45aaf2'; // tsenher budeg
    case 5:
      return '#009432'; // tod nogoon
    case 6:
      return '#e74c3c'; // ulaan
    default:
      return '#ffffff'; 
  }
}

export function isValidEmail(email) {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  )
  return pattern.test(email)
}

export function isValidPhone(phone) {
  return isNullOrEmpty(phone) || phone.length !== 8 ? false : true
}

export function getUser(data, id) {
  var i = null
  !isNullOrEmpty(data)
    ? data.map((item) => {
        if (item.id === id) {
          i = item
        }
      })
    : (i = null)
  return i
}

export const getLangCode = (country) => {
  var code = ''
  magicnum.travelLanguage.map((item) => {
    if (item.short_code == country) {
      code = item.code
    }
  })
  return code
}

export const getLangName = (country) => {
  var code = ''
  magicnum.travelLanguage.map((item) => {
    if (item.short_code == country) {
      code = item.name
    }
  })
  return code
}

export function buildAccorditionCard(item, itee) {
  var gg = magicnum.accreditationCategoryNew.filter((ite) => ite.id == item?.accr_category)
  var gg2 = magicnum.accreditationSubCategoryNew.filter((ite) => ite.id == item?.accr_sub_category)
  var ff = gg[0]?.num
  var f3 = gg2[0]?.num
  if (
    item?.accr_category == 'OC' ||
    item?.accr_category == 'Ss' ||
    item?.accr_category == 'St' ||
    item?.accr_category == 'So' ||
    item?.accr_category == 'Sl' ||
    item?.accr_category == 'S' ||
    item?.accr_category == 'W'
  ) {
    var f3 = 1
  }
  var ff1 = oron(itee?.category, 2)
  var ff2 = oron(item?.id, 3)
  var sport = isNullOrEmpty(ff1) ? '00' : ff1
  var func = isNullOrEmpty(ff2) ? 0 : ff2
  var noc = item?.noc
  if (noc == 11) {
    noc = 8
  }
  var i = noc + '' + ff + '' + f3 + '' + sport + '' + func
  return i
}

export function buildAccorditionCardVolunteer(itee) {
  var ff1 = oron(itee?.id, 7)
  var i = '8' + '7' + ff1
  return i
}

export function oron(text, count) {
  var urt = text?.toString()?.length
  var gg = text
  if (urt < count) {
    for (var i = 0; i < count - urt; i++) {
      gg = '0' + gg
    }
  }
  return gg
}

export function timeAgo(prevDate) {
  const diff = Number(new Date()) - Date.parse(prevDate)
  const minute = 60 * 1000
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const year = day * 365
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000)
      return `${seconds} ${seconds > 1 ? 'секундын' : 'секунд'} өмнө`
    case diff < hour:
      return Math.round(diff / minute) + ' минутын өмнө'
    case diff < day:
      return Math.round(diff / hour) + ' цагийн өмнө'
    case diff < month:
      return Math.round(diff / day) + ' өдрийн өмнө'
    case diff < year:
      return Math.round(diff / month) + ' сарын өмнө'
    case diff > year:
      return Math.round(diff / year) + ' жилийн өмнө'
    default:
      return 'nu'
  }
}

export function dateAgo(prevDate) {
  const diff = Number(new Date()) - Date.parse(prevDate)
  const minute = 60 * 1000
  const hour = minute * 60
  const day = hour * 24
  var gg = Math.round(diff / day)
  return gg * -1
}

const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789(-)abcdefghijklmnopqrstuvwxyz',

  // public method for encoding
  encode: function (input) {
    let output = ''
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4
    let i = 0

    input = Base64._utf8_encode(input)

    while (i < input.length) {
      chr1 = input.charCodeAt(i++)
      chr2 = input.charCodeAt(i++)
      chr3 = input.charCodeAt(i++)

      enc1 = chr1 >> 2
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
      enc4 = chr3 & 63

      if (isNaN(chr2)) {
        enc3 = enc4 = 64
      } else if (isNaN(chr3)) {
        enc4 = 64
      }

      output =
        output +
        Base64._keyStr.charAt(enc1) +
        Base64._keyStr.charAt(enc2) +
        Base64._keyStr.charAt(enc3) +
        Base64._keyStr.charAt(enc4)
    }

    return output
  },

  // public method for decoding
  decode: function (input) {
    let output = ''
    let chr1, chr2, chr3
    let enc1, enc2, enc3, enc4
    let i = 0

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '')

    while (i < input.length) {
      enc1 = Base64._keyStr.indexOf(input.charAt(i++))
      enc2 = Base64._keyStr.indexOf(input.charAt(i++))
      enc3 = Base64._keyStr.indexOf(input.charAt(i++))
      enc4 = Base64._keyStr.indexOf(input.charAt(i++))

      chr1 = (enc1 << 2) | (enc2 >> 4)
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
      chr3 = ((enc3 & 3) << 6) | enc4

      output = output + String.fromCharCode(chr1)

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2)
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3)
      }
    }

    output = Base64._utf8_decode(output)

    return output
  },

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    string = string.replace(/\r\n/g, '\n')
    let utftext = ''

    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n)

      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }

    return utftext
  },

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    let string = ''
    let i = 0,
      c1 = 0,
      c2 = 0,
      c3 = 0
    let c = (c1 = c2 = 0)

    while (i < utftext.length) {
      c = utftext.charCodeAt(i)

      if (c < 128) {
        string += String.fromCharCode(c)
        i++
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1)
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
        i += 2
      } else {
        c2 = utftext.charCodeAt(i + 1)
        c3 = utftext.charCodeAt(i + 2)
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
        i += 3
      }
    }
    return string
  },
}

export function encodeString(str) {
  return Base64.encode(str)
}
export function decodeString(str) {
  return Base64.decode(str)
}
