import {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {isNullOrEmpty} from '../../../../services/Utils'
import {DesignerAdd, DesignerUpdate, printTemplateEdit} from '../../../../services/main'
import {
  DesignerAddReq,
  DesignerUpdateReq,
  magicnum,
  printTemplateEditReq,
} from '../../../../services/Request'
import {use} from 'i18next'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const EditTemplate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [size, setSize] = useState('')
  const [orientation, setOrientation] = useState('')
  const [qr, setQr] = useState('')
  const [logo, setLogo] = useState('')
  const [validate, setValidate] = useState<any>([])
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])

  useEffect(() => {
    setName(item?.name)
    setSelectedIndices(
      item?.category
        .split('-')
        .filter((num: any) => num)
        .map((num: any) => Math.abs(parseInt(num)))
    )
    setSize(item?.size)
    setOrientation(item?.orentation)
    setQr(item?.qr)
    setLogo(item?.logo)
  }, [item])

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = printTemplateEditReq
      req.design.name = name
      req.design.category = category
      req.design.logo = logo
      req.design.qr = qr
      req.design.size = size
      req.design.orentation = orientation
      req.design.id = item.id
      printTemplateEdit(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const handleCheckboxChange = (index: number) => {
    setSelectedIndices(
      (prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index) // Deselect
          : [...prev, index] // Select
    )
  }
  useEffect(() => {
    if (selectedIndices.length > 0) {
      setCategory(`-${selectedIndices.sort((a, b) => a - b).join('-')}-`)
    } else {
      setCategory('')
    }
  }, [selectedIndices])

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-4 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Загвар засах</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>Загвар засах</span>
        </div>
        <div className='flex gap-12'>
          <div className='flex flex-col gap-2'>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                placeholder={t('common:USERS.name')}
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setLogo(e.target.value)
                  validateChange('logo', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='logo'
                id=''
                value={logo}
              >
                <option value={1}>Логотой</option>
                <option value={2}>Логогүй</option>
              </select>
              {validate['logo'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setQr(e.target.value)
                  validateChange('qr', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='size'
                id=''
                value={qr}
              >
                <option value={0}>QR-гүй</option>
                <option value={1}>QR-тай</option>
              </select>
              {validate['size'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setSize(e.target.value)
                  validateChange('size', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='size'
                id=''
                value={size}
              >
                <option value='st'>стандарт</option>
                <option value='stp'>стандарт бус</option>
              </select>
              {validate['size'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setOrientation(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                value={orientation}
                id=''
              >
                <option value='l'>Босоо</option>
                <option value='p'>Хөндлөн</option>
              </select>
              {validate['orientation'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='mb-4' style={{position: 'relative'}}>
          <div
            style={{
              display: 'flex',
              flexDirection:"column"
            }}
          >
            <h3>Categories</h3>
            {magicnum.nameCardCategory.map((el: any) => {
              return (
                <div
                  key={el}
                  style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    padding: '1px 8px',
                    userSelect: 'none',
                  }}
                >
                  <input
                    type='checkbox'
                    id={el}
                    checked={selectedIndices.includes(el)}
                    onChange={() => handleCheckboxChange(el)}
                  />
                  <label htmlFor={el}>{t(`common:magicnum.nameCardCategory.${el}`)}</label>
                </div>
              )
            })}
          </div>
          {/* {categoryModal ? (
            <div>
              
              <div
                style={{
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  zIndex: '0',
                }}
                onClick={() => setCategoryModal(false)}
              ></div>
            </div>
          ) : null} */}
        </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EditTemplate}
