import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {
  BaseRequest,
  printElementCollectionConnectlistReq,
  printElementCollectionConnectReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  printElementCollectionConnect,
  printElementCollectionConnectdelete,
  printElementCollectionConnectlist,
  printElementCollectionGet,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {NameCardElement} from '../element'
import {isNullOrEmpty} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Элемэнтбагц',
    path: '/elementbundle',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: any
}

const ElelemetBundleMore: FC<Props> = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [connectedElements, setconnectedElements] = useState<any>([])
  const [PID, setPID] = useState<any>(1)
  const [sizePerPage, setSizePerPage] = useState<any>(100)
  const [included, setIncluded] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loadingaddTobagts, setLoadingaddTobagts] = useState<boolean>(false)

  useEffect(() => {
    get()
    list()
  }, [id])

  useEffect(() => {
    var temparr: any[] = []
    connectedElements.map((el: any) => {
      temparr.push(el.eid)
    })
    setIncluded(temparr)
  }, [connectedElements])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    printElementCollectionGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = printElementCollectionConnectlistReq
    req.filter.cid = id as string
    req.filter.eid = ''
    req.pid = PID
    req.pSize = sizePerPage
    printElementCollectionConnectlist(req, onSuccessList, onFailed, 'POST')
  }

  const onSuccessList = (res: any) => {
    setconnectedElements(res.data)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingaddTobagts(false)
    return toast.error(error)
  }

  const add = (eid: any, url: any) => {
    setLoading(true)
    var req = printElementCollectionConnectReq
    req.collection.cid = id as string
    req.collection.eid = eid
    req.collection.url = url
    printElementCollectionConnect(req, onAddSuccess, onFailed, 'POST')
  }

  const onAddSuccess = (response: any) => {
    setLoadingaddTobagts(false)
    list()
    return toast.success('Амжилттай холболоо.')
  }

  const submit = (row: any) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <p>{t('common:confirmDelete')}</p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    printElementCollectionConnectdelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{id}</PageTitle>
      <div className='flex flex-col gap-5'>
        <div className='card p-8 '>
          <div className='flex gap-3' style={{alignItems:'baseline'}}>
            <h2 className='mb-4'>{data?.name}</h2>
            <span style={{fontWeight:'500'}}>/Нийт: {connectedElements?.length} элемент/</span>
          </div>

          <div className='flex flex-wrap gap-2'>
            {connectedElements.map((el: any) => {
              return (
                <div key={el.id} className='element-box'>
                  <img width={60} height={60} src={CDN_URL + el.url} alt='' />
                  <div onClick={() => submit(el)} className='delete-btn'>
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='card' style={{height:"80vh", overflowY:"auto"}}>
          <NameCardElement type='nemeh' addToCollection={add} included={included} loadingaddTobagts={loadingaddTobagts}/>
        </div>
      </div>
    </>
  )
}

export {ElelemetBundleMore}
