import {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {
  printElementCollectionAdd,
  printElementCollectionUpdate,
} from '../../../../services/main'
import {
  printElementCollectionAddReq,
  printElementCollectionUpdateReq,
} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {isNullOrEmpty} from '../../../../services/Utils'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const AddElementBundle: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [validate, setValidate] = useState<any>([])
  const [EmployeeID, updateEmployeeID] = useCookie('GAEmployeeID', null)

  useEffect(() => {
    setName(item?.name)
  }, [item])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = printElementCollectionAddReq
      req.collection.name = name
      req.collection.created_by = EmployeeID
      printElementCollectionAdd(req, onUpdateSuccess, onFailed, 'POST')
    }
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = printElementCollectionUpdateReq
      req.collection.name = name
      req.collection.id = item?.id
      printElementCollectionUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    onAddSuccess()
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-4 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'> {!isNullOrEmpty(item) ? 'Элемент багц засах' : 'Элемент багц нэмэх'} </h1>
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {!isNullOrEmpty(item) ? 'Элемент багц засах' : 'Элемент багц нэмэх'}
          </span>
        </div>
        <div className='flex gap-12'>
          <div className='flex flex-col gap-2' style={{minWidth: '250px'}}>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                placeholder={t('common:USERS.add')}
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={!isNullOrEmpty(item) ? update : add}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {AddElementBundle}
