import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {
  BaseRequest,
  printElementCollectionConnectlistReq,
  printElementCollectionConnectReq,
  PrintTemplateConnectbackReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  printElementCollectionConnectdelete,
  PrintTemplateBackget,
  PrintTemplateConnectback,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
// import {NameCardElement} from '../element'
import {isNullOrEmpty} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'
import {BackTemplates} from '../backtemplates'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Элемэнтбагц',
    path: '/elementbundle',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  back?: any
  setCount?:any
}

const TemplateBack: FC<Props> = ({back, setCount}) => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [connectedElements, setconnectedElements] = useState<any>([])
  const [PID, setPID] = useState<any>(1)
  const [sizePerPage, setSizePerPage] = useState<any>(100)
  const [included, setIncluded] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loadingaddTobagts, setLoadingaddTobagts] = useState<boolean>(false)

  useEffect(() => {
    if (!isNullOrEmpty(back.back_id)) {
      get()
    }
  }, [back])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    PrintTemplateBackget(back.back_id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingaddTobagts(false)
    return toast.error(error)
  }

  const add = (eid: any) => {
    setLoading(true)
    var req = PrintTemplateConnectbackReq
    req.design.id = id as string
    req.design.back_id = eid
    PrintTemplateConnectback(req, onAddSuccess, onFailed, 'PUT')
  }

  const onAddSuccess = (response: any) => {
    setCount()
    return toast.success('Амжилттай холболоо.')
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{id}</PageTitle>
      <div className='flex flex-col gap-5'>
        <div className='card p-8 '>
          {!isNullOrEmpty(back.back_id) ? (
            <div className='flex gap-5'>
              <img height={150} src={CDN_URL + data.preview} alt='' />
              <div
                className='cursor-pointer flex items-center gap-2 btn btn-warning btn-sm '
                style={{height: '32px'}}
                onClick={() => add(null)}
              >
                <i className='fa-solid fa-trash'></i> Устгах
              </div>
            </div>
          ) : (
            <div>empty</div>
          )}
        </div>
        <div className='card' style={{height: '80vh', overflowY: 'auto'}}>
          <BackTemplates type='holboh' add={add} />
        </div>
      </div>
    </>
  )
}

export {TemplateBack}
