import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {getUser} from '../../../../services/Utils'
import {
  BaseRequest,
  DesignerDesignerlistReq,
  magicnum,
  printElementCollectionchangestatusReq,
  printElementCollectionListReq,
  printElementListReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  DesignerDesignerlist,
  printElementCollectionchangestatus,
  printElementCollectionDelete,
  printElementCollectionList,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import CustomModal from '../../../modules/CustomModal/modal'
import {Link} from 'react-router-dom'
import {AddElementBundle} from './add'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
}

const ElementBundle: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [designerData, setDesignerData] = useState<any>([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showFilter, setShowFilter] = useState(false)
  const [status, setStatus] = useState('')
  const [designer, setdesigner] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    list()
    designerlist()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = printElementCollectionListReq
    req.filter.status = status
    req.filter.name = name
    req.pid = PID
    req.pSize = sizePerPage
    printElementCollectionList(req, onSuccessElement, onListFailed, 'POST')
  }
  const onSuccessElement = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  console.log(data)
  const onListFailed = (error: any) => {
    setLoading(false)
  }

  const designerlist = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DesignerDesignerlistReq
    DesignerDesignerlist(req, onDesinerSuccess, onFailed, 'POST')
  }
  const onDesinerSuccess = (response: any) => {
    setLoading(false)
    setDesignerData(response.data)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-5'>
        <span style={{fontWeight: '600', display: 'flex', alignItems: 'center'}}>{cell}</span>

        <div className='flex gap-1 items-center'>
          {row.styles.length > 5 ? (
            <div className='flex gap-1'>
              {row.styles.slice(0, 5).map((el: any, i: any) => {
                return (
                  <div
                    style={{
                      height: '50px',
                      width: '50px',
                      backgroundColor: '#eee',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    key={i}
                  >
                    <img height={40} width={40} src={CDN_URL + el.url} alt='' />
                  </div>
                )
              })}
              <span
                style={{
                  height: '50px',
                  width: '50px',
                  backgroundColor: '#eee',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  fontWeight: '500',
                  color: 'gray',
                }}
              >
                +{row.styles.length - 5}
              </span>
            </div>
          ) : row.styles[0].url === null ? (
            <div
              style={{
                backgroundColor: '#eee',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '500',
                color: 'gray',
                padding: '2px 8px',
              }}
            >
              empty
            </div>
          ) : (
            row.styles.map((el: any, i: any) => {
              return (
                <div
                  style={{
                    height: '50px',
                    width: '50px',
                    backgroundColor: '#eee',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  key={i}
                >
                  <img height={40} width={40} src={CDN_URL + el.url} alt='' />
                </div>
              )
            })
          )}
        </div>
      </div>
    )
  }

  const userRowFormat = (cell: any, row: any) => {
    const userinfo = getUser(designerData, cell) || {name: undefined}
    return <div className='flex justify-center'>{userinfo?.name}</div>
  }

  function actionRowFormat(cell: string, row: any) {
    return (
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <div className='flex'>
          <Link
            to={`/elementbundle/${row.id}`}
            title='More'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <i className='fa-solid fa-bars'></i>
          </Link>
          <div
            title='Засах'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              setItem(row)
              setEditModal(true)
            }}
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </div>
          <div
            title='Delete'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => submit(row, 'deleteMain')}
          >
            <KTIcon iconName='trash' className='fs-3' />
          </div>
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            {type === 'deleteMain' ? (
              <img style={{width: '100px'}} src={CDN_URL + row.url} alt='' />
            ) : null}
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    printElementCollectionDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => submit(row, 'status')}
        style={{
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '6px',
          width: '80px',
          backgroundColor: `${cell === 0 ? 'red' : cell === 1 ? 'rgb(76, 209, 55)' : null}`,
          cursor: 'pointer',
          margin: '0 auto',
        }}
      >
        {t(`common:magicnum.Status.${cell}`)}
      </div>
    )
  }

  const changeStatus = (row: any) => {
    var req = printElementCollectionchangestatusReq
    req.collection.id = row.id
    req.collection.status = row.status === 1 ? '0' : '1'
    printElementCollectionchangestatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onFailed = (error: any) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    setdesigner('')
    setStatus('')
    setPID(1)
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Багц',
        dataField: 'name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Хэрэглэгч',
        dataField: 'created_by',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Статус',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үйлдэл',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  const onAddSuccess = () => {
    list()
    setEditModal(false)
  }

  return (
    <>
      {editModal ? (
        <CustomModal visible={editModal} closeModal={() => setEditModal(false)} className=''>
          <AddElementBundle t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Элемент-багц</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div
              onClick={() => {
                list()
                setPID(1)
              }}
              className='card-toolbar'
            >
              <div className='btn btn-sm btn-light-primary'>{t('common:search')}</div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                {/* <div className='col'>
                  <select
                    onChange={(e) => {
                      setdesigner(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='designer'
                    value={designer}
                    id=''
                  >
                    <option value=''>Дизайнер - {t('common:all')}</option>
                    {designerData.map((el: any) => {
                      return <option value={el.id}>{el.name}</option>
                    })}
                  </select>
                </div> */}
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Name'
                    value={name}
                  />
                </div>

                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>Статус - {t('common:all')}</option>
                    {magicnum.status.map((el: any) => {
                      return <option value={el}>{t(`common:magicnum.Status.${el}`)}</option>
                    })}
                  </select>
                </div>
                <div className='col'>
                  <div
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Элемент багц</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт: {PTotal} элемент багц</span>
          </h3>
          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setEditModal(true)
              }}
            >
              <span className='text'>Элемент багц нэмэх</span>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
            key={data?.length}
          />
        </div>
      </div>
    </>
  )
}

export {ElementBundle}
