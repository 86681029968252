import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {
  dateTimeFormat,
  isNullOrEmpty,
  moneyFormat,
} from '../../../../services/Utils'
import {BaseRequest, PrintboxOrderDesignListreq, RGBtoCMYKreq} from '../../../../services/Request'
import {
  CDN_URL,
  PrintboxOrderDesignList,
  PrintboxOrderGet,
  RGBtoCMYK,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Orders',
    path: '/orders',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: any
}

const OrderMore: FC<Props> = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>('')
  const [imgs, setImgs] = useState<any>([])
  const [tab, setTab] = useState('image')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [validate, setValidate] = useState<any>([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [pNumber, setPNumber] = useState('')
  const [provider, setProvider] = useState('')
  const [isDesigner, setIsDesigner] = useState('')
  const [count, setCount] = useState(0)

  useEffect(() => {
    get()
  }, [id])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    PrintboxOrderGet(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response)
    orderList()
  }

  const orderList = () => {
    setLoading(true)
    var req = PrintboxOrderDesignListreq
    req.filter.type = ''
    req.filter.status = ''
    req.filter.oid = id as string
    PrintboxOrderDesignList(req, onListSuccess, onFailed, 'POST')
  }

  const RgbToCmykaa = (id: any) => {
    var req = RGBtoCMYKreq
    req.id = id
    RGBtoCMYK(req, onRGBSuccess, onFailed, 'POST')
  }

  const RgbDownload = async(el: any) => {
    try {
      const response = await fetch(CDN_URL + el.design);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlObject;
      link.download = el.oid + "-" + el.side;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Image download failed:", error);
    }
  }

  const onRGBSuccess = (response: any) => {
    console.log(response)
    setLoading(false)
  }

  const onListSuccess = (response: any) => {
    setImgs(response.data)
    setLoading(false)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
            <span style={{visibility: 'hidden'}}></span>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{t('common:USERS.activate')}</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                {t('common:USERS.activate')}
              </span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                placeholder='name'
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                value={email}
                placeholder='email'
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateChange('email', e)
                }}
              ></input>
              {validate['email'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='mobile'
                onChange={(e) => {
                  setPNumber(e.target.value)
                  validateChange('pNumber', e)
                }}
                value={pNumber}
              ></input>
              {validate['pNumber'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setProvider(e.target.value)
                  validateChange('provider', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='provider'
                id=''
                value={provider}
              >
                <option value=''>{t('common:validSelect')}</option>
                <option value='google'>{t('common:USERS.google')}</option>
                <option value='facebook'>{t('common:USERS.facebook')}</option>
                <option value='grado'>{t('common:USERS.grado')}</option>
                <option value='web'>{t('common:USERS.web')}</option>
                <option value='admin'>{t('common:USERS.admin')}</option>
              </select>
              {validate['provider'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setIsDesigner(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='status'
                value={isDesigner}
                id=''
              >
                <option value={''}>{t('common:validSelect')}</option>

                <option value={'1'}>{t('common:USERS.designer')}</option>
                <option value={'0'}>{t('common:USERS.notDesigner')}</option>
              </select>
              {validate['isDesigner'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='pt-10' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                // onClick={isNullOrEmpty(data) ? save : update}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data.id}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header card-header-overflow border-1'>
          <div className='flex gap-8 w-full card-header-flex'>
            <div className='flex flex-wrap gap-6 p-8 infos' style={{fontSize:"14px", fontWeight:"500"}}>
              {!isNullOrEmpty(data.type) ? (
                <div className='flex gap-3 items-center'>
                  <i style={{fontSize: '18px'}} className='fa-solid fa-box'></i>
                  {/* <KTIcon iconName='abstract-1' iconType='outline' className='fs-2' /> */}
                  <span>{data.type}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.id) ? (
                <div className='flex gap-3 items-center'>
                  {/* <KTIcon iconName='barcode' iconType='outline' className='fs-2' /> */}
                  <i style={{fontSize: '16px'}} className='fa-solid fa-ruler-combined'></i>
                  <span>Урт:{data.width}</span>
                  <span>Өргөн:{data.length}</span>
                  <span>Өндөр:{data.height}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.bg) ? (
                <div className='flex gap-3 items-center'>
                  <i style={{fontSize: '16px'}} className='fa-solid fa-palette'></i>
                  {/* <KTIcon iconName='social-media' iconType='outline' className='fs-2' /> */}
                  <span>{data.bg}</span>
                </div>
              ) : null}

              {!isNullOrEmpty(data.price) ? (
                <div className='flex gap-3 items-center'>
                  {/* <KTIcon iconName='message-text-2' iconType='outline' className='fs-2' /> */}
                  <i style={{fontSize: '16px'}} className='fa-solid fa-money-bill-1'></i>
                  <span>{moneyFormat(data.price, 'MNT')}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.payment) ? (
                <div className='flex gap-3 items-center'>
                  <i style={{fontSize: '16px'}} className='fa-solid fa-receipt'></i>
                  <span>{data.payment}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.created_at) ? (
                <div className=' flex gap-3 items-center'>
                  <KTIcon iconName='calendar-2' iconType='outline' className='fs-2' />
                  <span>{dateTimeFormat(data.created_at)}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'image',
                })}
                onClick={() => setTab('image')}
                role='tab'
              >
                Зурагнууд
              </div>
            </li>
          </ul>
        </div>
        <form className='form'>
          {tab === 'image' ? (
            <div className='card-body py-6'>
              <div className='flex flex-col'>
                {imgs.map((el: any) => {
                  return (
                    <div className='flex items-center justify-between px-6'>
                      <div className='flex items-center gap-8'>
                        <h3 style={{textTransform: 'capitalize'}}>{el.side}</h3>
                        <div style={{height: '180px'}}>
                          <img
                            style={{objectFit: 'contain', height: '180px'}}
                            src={CDN_URL + el.design}
                            alt=''
                          />
                        </div>
                      </div>

                      <div className='flex gap-2'>
                        <div
                          className='btn btn-light-primary btn-sm'
                          onClick={() => RgbToCmykaa(el.id)}
                        >
                          CMYK татах
                        </div>
                        <div className='btn btn-primary btn-sm' onClick={() => RgbDownload(el)}>
                          RGB татах
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </>
  )
}

export {OrderMore}
