import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, getUser, isNullOrEmpty} from '../../../../services/Utils'
import {
  BaseRequest,
  DesignerDesignerlistReq,
  magicnum,
  printTtemplatelistWithStylesReq,
  templateChangeStatusReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  DesignerDesignerlist,
  PrintTemplateChangeStatus,
  PrintTemplateDelete,
  printTtemplatelistWithStyles,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import CustomModal from '../../../modules/CustomModal/modal'
import {Link} from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'
import {EditTemplate} from './add'
import {PutOrg} from './putOrg'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
}

const Templates: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [designerData, setDesignerData] = useState([])
  const [item, setItem] = useState([])
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [name, setName] = useState('')
  const [org_rd, setOrg_rd] = useState('')
  const [logo, setLogo] = useState('')
  const [qr, setQr] = useState('')
  const [size, setSize] = useState('')
  const [category, setCategory] = useState('')
  const [user, setUser] = useState('')
  const [orentation, setOrentation] = useState('')
  const [status, setStatus] = useState('')
  const [addOrgModal, setAddOrgModal] = useState(false)

  console.log(data)

  useEffect(() => {
    designerlist()
  }, [PID])

  useEffect(() => {
    if (designerData.length > 0) {
      list()
    }
  }, [designerData, PID])

  const designerlist = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DesignerDesignerlistReq
    DesignerDesignerlist(req, onDesinerSuccess, onFailed, 'POST')
  }
  const onDesinerSuccess = (response: any) => {
    setLoading(false)
    setDesignerData(response.data)
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = printTtemplatelistWithStylesReq
    req.filter.status = status
    req.filter.category = category
    req.filter.name = name
    req.filter.created_by = user
    req.filter.orentation = orentation
    req.filter.logo = logo
    req.filter.qr = qr
    req.filter.org_rd = org_rd
    req.pid = PID
    req.pSize = sizePerPage
    printTtemplatelistWithStyles(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  console.log(data)

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const dateRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center gap-2'>
        <div style={{textAlign: 'center'}}>{dateFormat(row?.created_at)}</div>
      </div>
    )
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span>{cell}</span>
  }
  const infoRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-1 justify-end'>
        <div style={{width: '88px'}} title='Standart' className='icon-box'>
          <div
            style={{
              color: row.size === 'stp' ? '#a1a5b7' : '#44bd32',
            }}
          >
            <span style={{fontSize: '12px', lineHeight: '100%'}}>
              {t(`common:magicnum.nameCardSize.${row.size}`)}
            </span>
          </div>
        </div>
        <div className='icon-box'>
          {row.orentation === 'p' ? (
            <div
              title='Босоо'
              style={{width: '10px', height: '20px', backgroundColor: '#44bd32'}}
            ></div>
          ) : (
            <div
              title='Хэвтээ'
              style={{width: '20px', height: '10px', backgroundColor: '#44bd32'}}
            ></div>
          )}
        </div>
        <div title='QR' className='icon-box'>
          <i
            className='fa-solid fa-qrcode fa-xl'
            style={{color: row.qr === 1 ? '#44bd32' : '', fontSize: '16px'}}
          ></i>{' '}
        </div>
        <div title='Лого' className='icon-box'>
          <i
            className='fa-regular fa-image fa-xl'
            style={{color: row.logo === 1 ? '#44bd32' : '', fontSize: '16px'}}
          ></i>
        </div>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => submit(row, 'status')}
        style={{
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '6px',
          width: '80px',
          backgroundColor: `${cell === 0 ? 'red' : cell === 1 ? 'rgb(76, 209, 55)' : null}`,
          cursor: 'pointer',
          margin: '0 auto',
        }}
      >
        {t(`common:magicnum.Status.${cell}`)}
      </div>
    )
  }
  const userRowFormat = (cell: any, row: any) => {
    const userinfo = getUser(designerData, cell) || {name: undefined}
    return <div className='flex items-center gap-4'>{userinfo?.name}</div>
  }

  function actionRowFormat(cell: string, row: any) {
    return (
      <div style={{maxWidth: '100px', display: 'flex', justifyContent: 'flex-end'}}>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setEditModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Add user to Costumer'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setAddOrgModal(true)
          }}
        >
          {isNullOrEmpty(row.org_rd) ? (
            <i className='fa-solid fa-city fs-5'></i>
          ) : (
            <i className='fa-solid fa-city fs-5' style={{color: 'green'}}></i>
          )}
        </div>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            {type === 'deleteMain' ? (
              <img style={{width: '100px'}} src={CDN_URL + row.styles[0].preview} alt='' />
            ) : null}
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    PrintTemplateDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = templateChangeStatusReq
    req.design.id = row.id
    req.design.status = row.status === 1 ? '0' : '1'
    PrintTemplateChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  const rowProviderFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center gap-4'>
        <div className='flex gap-2'>
          <Link
            to={`/template/${row.id}`}
            style={{objectFit: 'cover', width: '200px'}}
            className='flex flex-1 justify-center items-center px-2 '
          >
            <img
              style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'contain'}}
              width={200}
              // height={200}
              src={`${CDN_URL}${row.styles[0].preview}`}
              alt='Preview'
            />
          </Link>

          <Link to={`/template/${row.id}?side=back`}>
            {!isNullOrEmpty(row.back_preview) ? (
              <div style={{objectFit: 'cover', width: '200px'}} className='flex flex-1'>
                <img
                  style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'contain'}}
                  width={200}
                  // height={200}
                  src={`${CDN_URL}${row.back_preview}`}
                  alt='Preview'
                />
              </div>
            ) : (
              <div
                style={{
                  fontWeight: '500',
                  color: 'black',
                  textAlign: 'center',
                  padding: '4px',
                  margin: '0 auto',
                  border: '1px solid rgba(0,0,0,.3)',
                  height: '120px',
                  width: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  borderRadius: '12px',
                  cursor: 'pointer',
                }}
              >
                <i style={{fontSize: '24px'}} className='fa-solid fa-plus'></i>
                <span style={{color: 'rgba(0,0,0,.5)'}}>Арын загвар холбох</span>
              </div>
            )}
          </Link>

          <div className='flex flex-col gap-4'></div>
        </div>
        <div className='flex gap-1 z-50'>
          {row.styles.length > 1 &&
            row.styles.map((el: any) => {
              return (
                <div
                  onClick={() => {
                    setItem(row.styles)
                    updateShowModal(true)
                  }}
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundImage: `linear-gradient(to bottom, ${el.color})`,
                    cursor: 'pointer',
                  }}
                ></div>
              )
            })}
        </div>
      </div>
    )
  }

  const clearFilter = () => {
    setName('')
    setSize('')
    setOrentation('')
    setLogo('')
    setQr('')
    setStatus('')
    setUser('')
    setCategory('')
    setOrg_rd('')
    list()
  }
  const onAddSuccess = () => {
    setEditModal(false)
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Дизайн',
        dataField: 'Design',
        dataSort: false,
        dataFormat: rowProviderFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Нэр',
        dataField: 'name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Хэрэглэгч',
        dataField: 'created_by',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Он сар өдөр',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Статус',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Загвар',
        dataField: 'uid',
        dataSort: false,
        dataFormat: infoRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Үйлдэл',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal visible={showModal} closeModal={() => updateShowModal(false)} className=''>
          <div style={{width: '800px', maxHeight: '90vh'}}>
            <Carousel showArrows={true}>
              {item.map((el: any) => {
                return (
                  <img
                    style={{objectFit: 'contain', maxHeight: '400px'}}
                    src={CDN_URL + el.preview}
                    alt=''
                  />
                )
              })}
            </Carousel>
          </div>
        </CustomModal>
      ) : null}

      {editModal ? (
        <CustomModal visible={editModal} closeModal={() => setEditModal(false)} className=''>
          <EditTemplate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {addOrgModal ? (
        <CustomModal
          visible={addOrgModal}
          closeModal={() => setAddOrgModal(false)}
          className='VideoModal'
        >
          <PutOrg t={t} item={item} setcustomerAddModal={setAddOrgModal} listDes={list} />
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Template</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <div
                onClick={() => {
                  list()
                  setPID(1)
                }}
                className='btn btn-sm btn-light-primary'
              >
                {t('common:search')}
              </div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div style={{flexWrap: 'wrap', gap: '6px'}} className='row'>
                <div className='col' style={{minWidth: '200px', maxWidth: '200px'}}>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='name'
                    type='text'
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                    placeholder='Нэр'
                    value={name}
                  />
                </div>
                <div
                  className='col flex gap-4 items-center'
                  style={{minWidth: '200px', maxWidth: '200px'}}
                >
                  <select
                    onChange={(e) => {
                      setUser(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='user'
                    value={user}
                    id=''
                  >
                    <option value=''>Дизайнер - {t('common:all')} </option>
                    {designerData.map((el: any) => {
                      return <option value={el.id}>{el.name}</option>
                    })}
                  </select>
                </div>
                <div
                  className='col flex gap-4 items-center'
                  style={{minWidth: '200px', maxWidth: '200px'}}
                >
                  <select
                    onChange={(e) => {
                      setCategory(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='category'
                    value={category}
                    id=''
                  >
                    <option value=''>Category - {t('common:all')} </option>
                    {magicnum.nameCardCategory.map((el: any) => {
                      return (
                        <option value={el}>{t(`common:magicnum.nameCardCategory.${el}`)}</option>
                      )
                    })}
                  </select>
                </div>
                <div className='col' style={{minWidth: '200px', maxWidth: '200px'}}>
                  <select
                    onChange={(e) => {
                      setSize(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='size'
                    value={size}
                    id=''
                  >
                    <option value=''>Хэмжээ - {t('common:all')}</option>
                    <option value='st'>{t('common:magicnum.size.st')}</option>
                    <option value='stp'>{t('common:magicnum.size.stp')}</option>
                  </select>
                </div>
                <div className='col' style={{minWidth: '200px', maxWidth: '200px'}}>
                  <select
                    onChange={(e) => {
                      setOrentation(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid w-200px'
                    name='orentation'
                    value={orentation}
                    id=''
                  >
                    <option value=''>Хэвтээ, босоо - {t('common:all')}</option>
                    <option value='l'>{t('common:magicnum.orentation.l')}</option>
                    <option value='p'>{t('common:magicnum.orentation.p')}</option>
                  </select>
                </div>
                <div className='col' style={{minWidth: '200px', maxWidth: '200px'}}>
                  <select
                    onChange={(e) => {
                      setLogo(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='logo'
                    value={logo}
                    id='logo'
                  >
                    <option value=''>Лого - {t('common:all')} </option>
                    <option value='1'>{t('common:magicnum.logo.1')}</option>
                    <option value='0'>{t('common:magicnum.logo.0')}</option>
                  </select>
                </div>
                <div className='col' style={{minWidth: '200px', maxWidth: '200px'}}>
                  <select
                    onChange={(e) => {
                      setQr(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='qr'
                    value={qr}
                    id=''
                  >
                    <option value=''>QR - {t('common:all')} </option>
                    <option value='1'>{t('common:magicnum.qr.1')}</option>
                    <option value='0'>{t('common:magicnum.qr.0')}</option>
                  </select>
                </div>
                <div
                  className='col flex gap-4 items-center'
                  style={{minWidth: '200px', maxWidth: '200px'}}
                >
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>Статус - {t('common:all')} </option>
                    <option value='0'>{t('common:magicnum.Status.0')}</option>
                    <option value='1'>{t('common:magicnum.Status.1')}</option>
                  </select>
                </div>
                <div className='col' style={{minWidth: '200px', maxWidth: '200px'}}>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='rd'
                    type='text'
                    onChange={(e) => {
                      setOrg_rd(e.target.value)
                    }}
                    placeholder='Байгууллагын РД'
                    value={org_rd}
                  />
                </div>
                <div className='col'>
                  <div
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Templates</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total: {PTotal} templates</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
            key={data.length}
          />
        </div>
      </div>
    </>
  )
}

export {Templates}
