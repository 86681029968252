
import {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {printElementEdit} from '../../../../services/main'
import {magicnum, printElementEditReq} from '../../../../services/Request'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const EditElement: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [category, setCategory] = useState('')
  const [type, setType] = useState('')
  const [validate, setValidate] = useState<any>([])

  useEffect(() => {
    setType(item?.type)
    setCategory(item?.category)
  }, [item])

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = printElementEditReq
      req.element.type = type
      req.element.category = category
      req.element.id = item.id
      printElementEdit(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-4 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Элемент засах</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>элемент засах</span>
        </div>
        <div className='flex gap-12'>
          <div className='flex flex-col gap-2' style={{minWidth: '250px'}}>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setType(e.target.value)
                  validateChange('type', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='type'
                value={type}
                id=''
              >
                <option value=''>Төрөл - {t('common:all')}</option>
                {magicnum.ElementCategory.map((el: any) => {
                  return (
                    <option value={el}>{t(`common:magicnum.namecardElementType.${el}`)}</option>
                  )
                })}
              </select>
              {validate['type'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setCategory(e.target.value)
                  validateChange('category', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='category'
                value={category}
                id=''
              >
                <option value=''>Категори - {t('common:all')}</option>
                {magicnum.ElementSubCategory.filter((i: any) => i.parent === type).map(
                  (el: any) => {
                    return (
                      <option value={el.id}>
                        {t(`common:magicnum.namecardElementCategory.namecard-${el.id}`)}
                      </option>
                    )
                  }
                )}
              </select>
              {validate['category'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            {/* <div className='mb-4'>
              <select
                onChange={(e) => {
                  setSize(e.target.value)
                  validateChange('size', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='size'
                id=''
                value={size}
              >
                <option value='st'>стандарт</option>
                <option value='stp'>стандарт бус</option>
              </select>
              {validate['size'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setOrientation(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                value={orientation}
                id=''
              >
                <option value='l'>Босоо</option>
                <option value='p'>Хөндлөн</option>
              </select>
              {validate['orientation'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div> */}
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EditElement}
