export const BaseRequest = {
  lang: localStorage.getItem('Lang') === null ? 'mn' : localStorage.getItem('Lang'),
}

export const ListBase = {
  ...BaseRequest,
  pid: 1,
  pSize: 25,
}

export const magicnum = {
  // elementType: [
  //   {
  //     id:1,
  //     type:"nc"
  //   },
  //   {
  //     id:2,
  //     type:"nc"
  //   },
  //   {
  //     id:3,
  //     type:"nc"
  //   },
  //   {
  //     id:4,
  //     type:"nc"
  //   },
  //   {
  //     id:5,
  //     type:"nc"
  //   },
  //   {
  //     id:6,
  //     type:"nc"
  //   },
  // ],
  burelttei: [
    {
      id: '1',
      name: 'Зөөлөн 5C',
      image: '/businessCard/matirial/5c_lac.png',
      price: 600,
    },
    {
      id: '2',
      name: '5C лак',
      image: '/businessCard/matirial/5c_lac.png',
      price: 600,
    },
    {
      id: '3',
      name: 'Клеше даралт',
      image: '/businessCard/matirial/coating.png',
      price: 500,
    },
    {
      id: '4',
      name: 'Эмбосс',
      image: '/businessCard/matirial/emboss.png',
      price: 500,
    },
    {
      id: '5',
      name: 'Лазер зүсэлт',
      image: '/businessCard/matirial/laser.png',
      price: 700,
    },
  ],
  bureltgu: [
    {
      id: '1',
      name: 'Extra 240g',
      image: '/businessCard/matirial/foil.png',
      price: 350,
    },
    {
      id: '2',
      name: 'Matt 300g',
      image: '/businessCard/matirial/foil.png',
      price: 300,
    },
  ],
  nameCardCategory: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
  Status: {
    Active: 1,
    InActive: 0,
  },
  status: [0, 1],
  PrintStatus: [1, 2, 3, 4, 5, 6],

  NameCardTempQr: [0, 1],

  FeedBack: [1, 2, 3, 4, 5],

  Tags: [
    {
      id: '1-1',
      tags: ['Trendy', 'Trend', 'Загвар', 'Шинэлэг', 'Тренд', 'Орчин үеийн', 'Шинэ', 'Загварлаг'],
    },
    {
      id: '1-2',
      tags: ['Fb', 'post', 'Facebook', 'Фэйсбүүк', 'Media', 'Social', 'Marketing', 'сошиал'],
    },
    {
      id: '1-3',
      tags: [
        'Fb',
        'post',
        'Facebook',
        'Фэйсбүүк',
        'Media',
        'Social',
        'Marketing',
        'сошиал',
        'Story',
      ],
    },
    {
      id: '1-4',
      tags: [
        'Fb',
        'post',
        'Facebook',
        'Фэйсбүүк',
        'Media',
        'Social',
        'Marketing',
        'сошиал',
        'Event',
      ],
    },
    {
      id: '1-5',
      tags: ['Fb', 'post', 'Facebook', 'Фэйсбүүк', 'Media', 'Social', 'Marketing', 'сошиал'],
    },
    {
      id: '1-6',
      tags: ['Instagram', 'post', 'Insta', 'Story', 'Social', 'Media', 'Marketing'],
    },
    {
      id: '1-7',
      tags: ['Instagram', 'post', 'Insta', 'Story', 'Social', 'Media', 'Story'],
    },
    {
      id: '1-8',
      tags: ['Титэм үгс', 'quotes', 'quote', 'ишлэл', 'үгс', 'twitter'],
    },
    {
      id: '1-9',
      tags: ['youtube', 'YT', 'video', 'media'],
    },
    {
      id: '1-10',
      tags: ['үгс', 'онч үгс', 'quates', 'word'],
    },
    {
      id: '2-1',
      tags: ['Постер', 'Poster', 'Fb post', 'Insta post'],
    },
    {
      id: '2-2',
      tags: ['Урилга', 'Invitation', 'Invite', 'Urilga'],
    },
    {
      id: '2-3',
      tags: ['Мэндчилгээ', 'Mendchilgee', 'Greetings'],
    },
    {
      id: '2-4',
      tags: [
        'Өргөмжлөл',
        'Certificate',
        'Achievement',
        'Accomplishment',
        'Urgumjlul',
        'Batlamj',
        'Батламж',
      ],
    },
    {
      id: '2-5',
      tags: ['Меню', 'Menu', 'Цэс'],
    },
    {
      id: '2-6',
      tags: ['Брошур', 'Brochure', 'Folded', 'brochure', 'Танилцуулга', 'Broshur'],
    },
    {
      id: '2-7',
      tags: [
        'Ажилд авах зар',
        'Hiring',
        'Work',
        'HR',
        'Job',
        'Ажил',
        'Зар',
        'Ажлын зар',
        'Ажилд авна',
        'CV',
      ],
    },
    {
      id: '2-8',
      tags: ['Нэрийн хуудас', 'Namecard', 'Card', 'Business', 'Картны загвар'],
    },
    {
      id: '2-9',
      tags: ['Инфографик', 'Infographic', 'Статистик', 'Үзүүлэлт', 'Statistic'],
    },
    {
      id: '2-10',
      tags: ['Анкет', 'CV', 'Resume', 'Ажлын анкет', 'Анкет	товч танилцуулга'],
    },
    {
      id: '2-11',
      tags: ['Ажлын үнэмлэх', 'Employee card', 'Card', 'Namecard', 'Worker card', 'Үнэмлэх', 'ID'],
    },
    {
      id: '2-12',
      tags: ['Эрхийн бичиг', 'erhiin bichig', 'erkhiin bichig'],
    },
    {
      id: '2-13',
      tags: ['Гэрчилгээ', 'gerchilgee'],
    },
    {
      id: '2-14',
      tags: ['Presentation', 'PPT'],
    },
  ],

  EmployeeType: ['editor', 'admin'],
  UserType: ['designer', 'user'],

  WebUserType: [0, 1],
  PrivLevel: ['1', '2', '3', '4'],

  ElementCategory: ['namecard'],

  ElementSubCategory: [
    {
      parent: 'namecard',
      id: 1,
    },
    {
      parent: 'namecard',
      id: 2,
    },
    {
      parent: 'namecard',
      id: 3,
    },
    {
      parent: 'namecard',
      id: 4,
    },
    {
      parent: 'namecard',
      id: 5,
    },
    {
      parent: 'namecard',
      id: 6,
    },
    {
      parent: 'namecard',
      id: 7,
    },
    {
      parent: 'namecard',
      id: 8,
    },
    {
      parent: 'namecard',
      id: 9,
    },
    {
      parent: 'namecard',
      id: 10,
    },
    {
      parent: 'namecard',
      id: 11,
    },
    {
      parent: 'namecard',
      id: 12,
    },
    {
      parent: 'namecard',
      id: 13,
    },
    {
      parent: 'namecard',
      id: 14,
    },
  ],

  address_city: [
    {id: 1, name: 'Улаанбаатар'},
    {id: 2, name: 'Архангай'},
    {id: 3, name: 'Баян-Өлгий'},
    {id: 4, name: 'Баянхонгор'},
    {id: 5, name: 'Булган'},
    {id: 6, name: 'Говь-Алтай'},
    {id: 7, name: 'Говьсүмбэр'},
    {id: 8, name: 'Дархан-Уул'},
    {id: 9, name: 'Дорноговь'},
    {id: 10, name: 'Дорнод'},
    {id: 11, name: 'Дундговь'},
    {id: 12, name: 'Завхан'},
    {id: 13, name: 'Орхон'},
    {id: 14, name: 'Өвөрхангай'},
    {id: 15, name: 'Өмнөговь'},
    {id: 16, name: 'Сүхбаатар'},
    {id: 17, name: 'Сэлэнгэ'},
    {id: 18, name: 'Төв'},
    {id: 19, name: 'Увс'},
    {id: 20, name: 'Ховд'},
    {id: 21, name: 'Хөвсгөл'},
    {id: 22, name: 'Хэнтий'},
  ],
  address_district: [
    // arkhangai aimag
    {city_id: 2, id: 1, name: 'Эрдэнэбулган'},
    {city_id: 2, id: 2, name: 'Батцэнгэл'},
    {city_id: 2, id: 3, name: 'Булган'},
    {city_id: 2, id: 4, name: 'Жаргалант'},
    {city_id: 2, id: 5, name: 'Их Тамир'},
    {city_id: 2, id: 6, name: 'Тариат'},
    {city_id: 2, id: 7, name: 'Өгийнуур'},
    {city_id: 2, id: 8, name: 'Өлзийт'},
    {city_id: 2, id: 9, name: 'Өндөр-Улаан'},
    {city_id: 2, id: 10, name: 'Хотонт'},
    {city_id: 2, id: 11, name: 'Төвшрүүлэх'},
    {city_id: 2, id: 12, name: 'Хайрхан'},
    {city_id: 2, id: 13, name: 'Хангай'},
    {city_id: 2, id: 14, name: 'Хашаат'},
    {city_id: 2, id: 15, name: 'Цахир'},
    {city_id: 2, id: 16, name: 'Цэнхэр'},
    {city_id: 2, id: 17, name: 'Цэцэрлэг'},
    {city_id: 2, id: 18, name: 'Чулуут'},
    {city_id: 2, id: 19, name: 'Эрдэнэмандал'},
    //ulaanbaatar
    {city_id: 1, id: 1, name: 'Багануур'},
    {city_id: 1, id: 2, name: 'Багахангай'},
    {city_id: 1, id: 3, name: 'Баянгол'},
    {city_id: 1, id: 4, name: 'Сүхбаатар'},
    {city_id: 1, id: 5, name: 'Баянзүрх'},
    {city_id: 1, id: 6, name: 'Хан-Уул'},
    {city_id: 1, id: 7, name: 'Налайх'},
    {city_id: 1, id: 8, name: 'Сонгинохайрхан'},
    {city_id: 1, id: 9, name: 'Чингэлтэй'},
    //bayan-olgii
    {city_id: 3, id: 1, name: 'Алтай'},
    {city_id: 3, id: 2, name: 'Алтанцөгц'},
    {city_id: 3, id: 3, name: 'Өлгий'},
    {city_id: 3, id: 4, name: 'Баяннуур'},
    {city_id: 3, id: 5, name: 'Бугат'},
    {city_id: 3, id: 6, name: 'Булган'},
    {city_id: 3, id: 7, name: 'Буянт'},
    {city_id: 3, id: 8, name: 'Сагсай'},
    {city_id: 3, id: 9, name: 'Дэлүүн'},
    {city_id: 3, id: 10, name: 'Ногооннуур'},
    {city_id: 3, id: 11, name: 'Цэнгэл'},
    {city_id: 3, id: 12, name: 'Толбо'},
    {city_id: 3, id: 13, name: 'Улаанхус'},
    //bayankhongor
    {city_id: 4, id: 1, name: 'Баянхонгор'},
    {city_id: 4, id: 2, name: 'Баацагаан'},
    {city_id: 4, id: 3, name: 'Баян-Овоо'},
    {city_id: 4, id: 4, name: 'Баян-Өндөр'},
    {city_id: 4, id: 5, name: 'Баянбулаг'},
    {city_id: 4, id: 6, name: 'Бууцагаан'},
    {city_id: 4, id: 7, name: 'Баянговь'},
    {city_id: 4, id: 8, name: 'Баянлиг'},
    {city_id: 4, id: 9, name: 'Баянцагаан'},
    {city_id: 4, id: 10, name: 'Богд'},
    {city_id: 4, id: 11, name: 'Бөмбөгөр'},
    {city_id: 4, id: 12, name: 'Галуут'},
    {city_id: 4, id: 13, name: 'Гурванбулаг'},
    {city_id: 4, id: 14, name: 'Жаргалант'},
    {city_id: 4, id: 15, name: 'Жинст'},
    {city_id: 4, id: 16, name: 'Заг'},
    {city_id: 4, id: 17, name: 'Өлзийт'},
    {city_id: 4, id: 18, name: 'Хүрээмарал'},
    {city_id: 4, id: 19, name: 'Шинэжинст'},
    {city_id: 4, id: 20, name: 'Эрдэнэцогт'},
    //bulgan
    {city_id: 5, id: 1, name: 'Булган'},
    {city_id: 5, id: 2, name: 'Баян-Агт'},
    {city_id: 5, id: 3, name: 'Баяннуур'},
    {city_id: 5, id: 4, name: 'Бугат'},
    {city_id: 5, id: 5, name: 'Бүрэгхангай'},
    {city_id: 5, id: 6, name: 'Гурван Булаг'},
    {city_id: 5, id: 7, name: 'Дашинчилэн'},
    {city_id: 5, id: 8, name: 'Могод'},
    {city_id: 5, id: 9, name: 'Орхон'},
    {city_id: 5, id: 10, name: 'Рашаант'},
    {city_id: 5, id: 11, name: 'Сайхан'},
    {city_id: 5, id: 12, name: 'Сэлэнгэ'},
    {city_id: 5, id: 13, name: 'Тэшиг'},
    {city_id: 5, id: 14, name: 'Хутаг-Өндөр'},
    {city_id: 5, id: 15, name: 'Хангал'},
    {city_id: 5, id: 16, name: 'Хишиг-Өндөр'},
    // gobi-altai
    {city_id: 6, id: 1, name: 'Есөнбулаг'},
    {city_id: 6, id: 2, name: 'Алтай'},
    {city_id: 6, id: 3, name: 'Тонхил'},
    {city_id: 6, id: 4, name: 'Баян-Уул'},
    {city_id: 6, id: 5, name: 'Цогт'},
    {city_id: 6, id: 6, name: 'Бигэр'},
    {city_id: 6, id: 7, name: 'Бугат'},
    {city_id: 6, id: 8, name: 'Дэлгэр'},
    {city_id: 6, id: 9, name: 'Дарив'},
    {city_id: 6, id: 10, name: 'Жаргалан'},
    {city_id: 6, id: 11, name: 'Халиун'},
    {city_id: 6, id: 12, name: 'Тайшир'},
    {city_id: 6, id: 13, name: 'Төгрөг'},
    {city_id: 6, id: 14, name: 'Хөхморьт'},
    {city_id: 6, id: 15, name: 'Цээл'},
    {city_id: 6, id: 16, name: 'Чандмань'},
    {city_id: 6, id: 17, name: 'Шарга'},
    {city_id: 6, id: 18, name: 'Эрдэнэ'},
    // gobisumber
    {city_id: 7, id: 1, name: 'Сүмбэр'},
    //darhan
    {city_id: 8, id: 1, name: 'Дархан'},
    {city_id: 8, id: 2, name: 'Хонгор'},
    {city_id: 8, id: 3, name: 'Шарын Гол'},
    {city_id: 8, id: 4, name: 'Орхон'},
    //dornogobi
    {city_id: 9, id: 1, name: 'Айраг'},
    {city_id: 9, id: 2, name: 'Алтанширээ'},
    {city_id: 9, id: 3, name: 'Даланжаргалан'},
    {city_id: 9, id: 4, name: 'Дэлгэрэх'},
    {city_id: 9, id: 5, name: 'Замын-Үүд'},
    {city_id: 9, id: 6, name: 'Иххэт'},
    {city_id: 9, id: 7, name: 'Мандах'},
    {city_id: 9, id: 8, name: 'Өргөн'},
    {city_id: 9, id: 9, name: 'Сайншанд'},
    {city_id: 9, id: 10, name: 'Сайхандулаан'},
    {city_id: 9, id: 11, name: 'Улаанбадрах'},
    {city_id: 9, id: 12, name: 'Хатанбулаг'},
    {city_id: 9, id: 13, name: 'Хөвсгөл'},
    {city_id: 9, id: 14, name: 'Эрдэнэ'},
    //dornod
    {city_id: 10, id: 1, name: 'Хэрлэн'},
    {city_id: 10, id: 2, name: 'Баян-Уул'},
    {city_id: 10, id: 3, name: 'Баяндун'},
    {city_id: 10, id: 4, name: 'Баянтүмэн'},
    {city_id: 10, id: 5, name: 'Булган'},
    {city_id: 10, id: 6, name: 'Гурванзагал'},
    {city_id: 10, id: 7, name: 'Дашбалбар'},
    {city_id: 10, id: 8, name: 'Матад'},
    {city_id: 10, id: 9, name: 'Сэргэлэн'},
    {city_id: 10, id: 10, name: 'Халхгол'},
    {city_id: 10, id: 11, name: 'Хөлөнбуйр'},
    {city_id: 10, id: 12, name: 'Цагаан-Овоо'},
    {city_id: 10, id: 13, name: 'Чойбалсан'},
    {city_id: 10, id: 14, name: 'Чулуунхороот'},
    //dundgobi
    {city_id: 11, id: 1, name: 'Адаацаг'},
    {city_id: 11, id: 2, name: 'Баянжаргалан'},
    {city_id: 11, id: 3, name: 'Сайнцагаан'},
    {city_id: 11, id: 4, name: 'Говь-Угтаал'},
    {city_id: 11, id: 5, name: 'Гурвансайхан'},
    {city_id: 11, id: 6, name: 'Дэлгэрхангай'},
    {city_id: 11, id: 7, name: 'Дэлгэрцогт'},
    {city_id: 11, id: 8, name: 'Дэрэн'},
    {city_id: 11, id: 9, name: 'Луус'},
    {city_id: 11, id: 10, name: 'Өлзийт'},
    {city_id: 11, id: 11, name: 'Өндөршил'},
    {city_id: 11, id: 12, name: 'Сайхан-Овоо'},
    {city_id: 11, id: 13, name: 'Хулд'},
    {city_id: 11, id: 14, name: 'Цагаандэлгэр'},
    {city_id: 11, id: 15, name: 'Эрдэнэдалай'},
    //zavkhan
    {city_id: 12, id: 1, name: 'Улиастай'},
    {city_id: 12, id: 2, name: 'Алдархаан'},
    {city_id: 12, id: 3, name: 'Асгат'},
    {city_id: 12, id: 4, name: 'Баянтэс'},
    {city_id: 12, id: 5, name: 'Дөрвөлжин'},
    {city_id: 12, id: 6, name: 'Завханмандал'},
    {city_id: 12, id: 7, name: 'Идэр'},
    {city_id: 12, id: 8, name: 'Их-Уул'},
    {city_id: 12, id: 9, name: 'Нөмрөг'},
    {city_id: 12, id: 10, name: 'Отгон'},
    {city_id: 12, id: 15, name: 'Сантмаргац'},
    {city_id: 12, id: 11, name: 'Сонгино'},
    {city_id: 12, id: 12, name: 'Тосонцэнгэл'},
    {city_id: 12, id: 13, name: 'Түдэвтэй'},
    {city_id: 12, id: 14, name: 'Тэлмэн'},
    {city_id: 12, id: 15, name: 'Тэс'},
    {city_id: 12, id: 16, name: 'Ургамал'},
    {city_id: 12, id: 17, name: 'Цагаанхайрхан'},
    {city_id: 12, id: 18, name: 'Цагаанчулуут'},
    {city_id: 12, id: 19, name: 'Цэцэн-Уул'},
    {city_id: 12, id: 20, name: 'Шилүүстэй'},
    {city_id: 12, id: 21, name: 'Эрдэнэхайрхан'},
    {city_id: 12, id: 21, name: 'Яруу'},
    //orkhon
    {city_id: 13, id: 1, name: 'Баян-Өндөр'},
    {city_id: 13, id: 2, name: 'Жаргалант'},
    //ovorhangai
    {city_id: 14, id: 1, name: 'Арвайхээр'},
    {city_id: 14, id: 2, name: 'Баруунбаян-Улаан'},
    {city_id: 14, id: 3, name: 'Бат-Өлзий'},
    {city_id: 14, id: 4, name: 'Баян-Өндөр'},
    {city_id: 14, id: 5, name: 'Баянгол'},
    {city_id: 14, id: 6, name: 'Богд'},
    {city_id: 14, id: 7, name: 'Бүрд'},
    {city_id: 14, id: 8, name: 'Гучин-Ус'},
    {city_id: 14, id: 9, name: 'Есөн Зүйл'},
    {city_id: 14, id: 10, name: 'Зүүнбаян-Улаан'},
    {city_id: 14, id: 11, name: 'Нарийнтээл'},
    {city_id: 14, id: 12, name: 'Өлзийт'},
    {city_id: 14, id: 13, name: 'Сант'},
    {city_id: 14, id: 14, name: 'Тарагт'},
    {city_id: 14, id: 15, name: 'Төгрөг'},
    {city_id: 14, id: 16, name: 'Уянга'},
    {city_id: 14, id: 17, name: 'Хайрхандулаан'},
    {city_id: 14, id: 18, name: 'Хархорин'},
    {city_id: 14, id: 19, name: 'Хужирт'},
    //omnogobi
    {city_id: 15, id: 1, name: 'Баян-Овоо'},
    {city_id: 15, id: 2, name: 'Баяндалай'},
    {city_id: 15, id: 3, name: 'Булган'},
    {city_id: 15, id: 4, name: 'Гурвантэс'},
    {city_id: 15, id: 5, name: 'Даланзадгад'},
    {city_id: 15, id: 6, name: 'Мандал-Овоо'},
    {city_id: 15, id: 7, name: 'Манлай'},
    {city_id: 15, id: 8, name: 'Ноён'},
    {city_id: 15, id: 9, name: 'Номгон'},
    {city_id: 15, id: 10, name: 'Сэврэй'},
    {city_id: 15, id: 11, name: 'Ханхонгор'},
    {city_id: 15, id: 12, name: 'Ханбогд'},
    {city_id: 15, id: 13, name: 'Хүрмэн'},
    {city_id: 15, id: 14, name: 'Цогт-Овоо'},
    {city_id: 15, id: 15, name: 'Цогтцэций'},
    //sukhbaatar
    {city_id: 16, id: 1, name: 'Баруун-Урт'},
    {city_id: 16, id: 2, name: 'Асгат'},
    {city_id: 16, id: 3, name: 'Баяндэлгэр'},
    {city_id: 16, id: 4, name: 'Дарьганга'},
    {city_id: 16, id: 5, name: 'Мөнххаан'},
    {city_id: 16, id: 6, name: 'Наран'},
    {city_id: 16, id: 7, name: 'Онгон'},
    {city_id: 16, id: 8, name: 'Сүхбаатар'},
    {city_id: 16, id: 9, name: 'Түвшинширээ'},
    {city_id: 16, id: 10, name: 'Түмэнцогт'},
    {city_id: 16, id: 11, name: 'Уулбаян'},
    {city_id: 16, id: 12, name: 'Халзан'},
    {city_id: 16, id: 13, name: 'Эрдэнэцагаан'},
    //selenge
    {city_id: 17, id: 1, name: 'Алтанбулаг'},
    {city_id: 17, id: 2, name: 'Баруунбүрэн'},
    {city_id: 17, id: 3, name: 'Баянгол'},
    {city_id: 17, id: 4, name: 'Ерөө'},
    {city_id: 17, id: 5, name: 'Шаамар'},
    {city_id: 17, id: 6, name: 'Жавхлант'},
    {city_id: 17, id: 7, name: 'Зүүнбүрэн'},
    {city_id: 17, id: 8, name: 'Мандал'},
    {city_id: 17, id: 9, name: 'Сүхбаатар'},
    {city_id: 17, id: 10, name: 'Сайхан'},
    {city_id: 17, id: 11, name: 'Орхон'},
    {city_id: 17, id: 12, name: 'Орхонтуул'},
    {city_id: 17, id: 13, name: 'Сант'},
    {city_id: 17, id: 14, name: 'Түшиг'},
    {city_id: 17, id: 15, name: 'Хушаат'},
    {city_id: 17, id: 16, name: 'Хүдэр'},
    {city_id: 17, id: 17, name: 'Цагааннуур'},
    //tov aimag
    {city_id: 18, id: 1, name: 'Зуунмод'},
    {city_id: 18, id: 2, name: 'Алтанбулаг'},
    {city_id: 18, id: 3, name: 'Аргалант'},
    {city_id: 18, id: 4, name: 'Архуст'},
    {city_id: 18, id: 5, name: 'Батсүмбэр'},
    {city_id: 18, id: 6, name: 'Баян'},
    {city_id: 18, id: 7, name: 'Баян-Өнжүүл'},
    {city_id: 18, id: 8, name: 'Баяндэлгэр'},
    {city_id: 18, id: 9, name: 'Баянжаргалан'},
    {city_id: 18, id: 10, name: 'Баянхангай'},
    {city_id: 18, id: 11, name: 'Баянцагаан'},
    {city_id: 18, id: 12, name: 'Баянцогт'},
    {city_id: 18, id: 13, name: 'Баянчандмань'},
    {city_id: 18, id: 14, name: 'Борнуур'},
    {city_id: 18, id: 15, name: 'Бүрэн'},
    {city_id: 18, id: 16, name: 'Дэлгэрхаан'},
    {city_id: 18, id: 17, name: 'Жаргалант'},
    {city_id: 18, id: 18, name: 'Заамар'},
    {city_id: 18, id: 19, name: 'Лүн'},
    {city_id: 18, id: 20, name: 'Мөнгөнморьт'},
    {city_id: 18, id: 21, name: 'Өндөрширээт'},
    {city_id: 18, id: 22, name: 'Сүмбэр'},
    {city_id: 18, id: 23, name: 'Сэргэлэн'},
    {city_id: 18, id: 24, name: 'Угтаалцайдам'},
    {city_id: 18, id: 25, name: 'Цээл'},
    {city_id: 18, id: 26, name: 'Эрдэнэ'},
    {city_id: 18, id: 27, name: 'Эрдэнэсант'},
    //uvs
    {city_id: 19, id: 1, name: 'Улаангом'},
    {city_id: 19, id: 2, name: 'Баруунтуруун'},
    {city_id: 19, id: 3, name: 'Бөхмөрөн'},
    {city_id: 19, id: 4, name: 'Давст'},
    {city_id: 19, id: 5, name: 'Өмнөговь'},
    {city_id: 19, id: 6, name: 'Завхан'},
    {city_id: 19, id: 7, name: 'Зүүнговь'},
    {city_id: 19, id: 8, name: 'Зүүнхангай'},
    {city_id: 19, id: 9, name: 'Малчин'},
    {city_id: 19, id: 10, name: 'Наранбулаг'},
    {city_id: 19, id: 11, name: 'Өлгий'},
    {city_id: 19, id: 12, name: 'Өндөрхангай'},
    {city_id: 19, id: 13, name: 'Сагил'},
    {city_id: 19, id: 14, name: 'Тариалан'},
    {city_id: 19, id: 15, name: 'Түргэн'},
    {city_id: 19, id: 16, name: 'Тэс'},
    {city_id: 19, id: 17, name: 'Ховд'},
    {city_id: 19, id: 18, name: 'Хяргас'},
    {city_id: 19, id: 19, name: 'Цагаанхайрхан'},
    //khovd
    {city_id: 20, id: 1, name: 'Жаргалант'},
    {city_id: 20, id: 2, name: 'Алтай'},
    {city_id: 20, id: 3, name: 'Булган'},
    {city_id: 20, id: 4, name: 'Буянт'},
    {city_id: 20, id: 5, name: 'Дарви'},
    {city_id: 20, id: 6, name: 'Дөргөн'},
    {city_id: 20, id: 7, name: 'Дуут'},
    {city_id: 20, id: 8, name: 'Зэрэг'},
    {city_id: 20, id: 9, name: 'Манхан'},
    {city_id: 20, id: 10, name: 'Мөнххайрхан'},
    {city_id: 20, id: 11, name: 'Мөст'},
    {city_id: 20, id: 12, name: 'Мянгад'},
    {city_id: 20, id: 13, name: 'Үенч'},
    {city_id: 20, id: 14, name: 'Ховд'},
    {city_id: 20, id: 15, name: 'Цэцэг'},
    {city_id: 20, id: 16, name: 'Чандмань'},
    {city_id: 20, id: 17, name: 'Эрдэнэбүрэн'},
    //khovsgol
    {city_id: 21, id: 1, name: 'Мөрөн'},
    {city_id: 21, id: 2, name: 'Алаг-Эрдэнэ'},
    {city_id: 21, id: 3, name: 'Арбулаг'},
    {city_id: 21, id: 4, name: 'Баянзүрх'},
    {city_id: 21, id: 5, name: 'Бүрэнтогтох'},
    {city_id: 21, id: 6, name: 'Галт'},
    {city_id: 21, id: 7, name: 'Жаргалант'},
    {city_id: 21, id: 8, name: 'Их-Уул'},
    {city_id: 21, id: 9, name: 'Цэцэрлэг'},
    {city_id: 21, id: 10, name: 'Рашаант'},
    {city_id: 21, id: 11, name: 'Рэнчинлхүмбэ'},
    {city_id: 21, id: 12, name: 'Тариалан'},
    {city_id: 21, id: 13, name: 'Тосонцэнгэл'},
    {city_id: 21, id: 14, name: 'Төмөрбулаг'},
    {city_id: 21, id: 15, name: 'Түнэл'},
    {city_id: 21, id: 16, name: 'Улаан-Уул'},
    {city_id: 21, id: 17, name: 'Ханх'},
    {city_id: 21, id: 18, name: 'Цагаан-Уул'},
    {city_id: 21, id: 19, name: 'Цагаан-Үүр'},
    {city_id: 21, id: 20, name: 'Цагааннуур'},
    {city_id: 21, id: 21, name: 'Чандмань-Өндөр'},
    {city_id: 21, id: 22, name: 'Шинэ-Идэр'},
    {city_id: 21, id: 23, name: 'Эрдэнэбулган'},
    //khentii
    {city_id: 22, id: 1, name: 'Батноров'},
    {city_id: 22, id: 2, name: 'Батширээт'},
    {city_id: 22, id: 3, name: 'Баян-Адарга'},
    {city_id: 22, id: 4, name: 'Баян-Овоо'},
    {city_id: 22, id: 5, name: 'Баянмөнх'},
    {city_id: 22, id: 6, name: 'Баянхутаг'},
    {city_id: 22, id: 7, name: 'Биндэр'},
    {city_id: 22, id: 8, name: 'Бор-Өндөр'},
    {city_id: 22, id: 9, name: 'Галшар'},
    {city_id: 22, id: 10, name: 'Өмнөдэлгэр'},
    {city_id: 22, id: 11, name: 'Дадал'},
    {city_id: 22, id: 12, name: 'Дархан'},
    {city_id: 22, id: 13, name: 'Дэлгэрхаан'},
    {city_id: 22, id: 14, name: 'Жаргалтхаан'},
    {city_id: 22, id: 15, name: 'Мөрөн'},
    {city_id: 22, id: 16, name: 'Хэрлэн'},
    {city_id: 22, id: 17, name: 'Норовлин'},
    {city_id: 22, id: 18, name: 'Цэнхэрмандал'},
  ],
}

//#region employee
export const AuthReq = {
  ...BaseRequest,
  Username: '',
  Password: '',
  Status: '',
  FirstName: '',
  LastName: '',
  ExpiredDate: '',
  TypeID: '',
  file: '',
}

export const AuthCreateReq = {
  ...BaseRequest,
  UserName: '',
  Password: '',
}

export const ChangePassReq = {
  ...BaseRequest,
  EmployeeID: '',
  newPassword: '',
  OLdPass: '',
  IsAdmin: false,
  realOldPass: '',
}

export const EmployeeChangeInfoReq = {
  ...BaseRequest,
  FirstName: '',
  LastName: '',
  Username: '',
  Status: 0,
  TypeID: '',
  Noc: '',
  id: '',
}

export const EmployeeAddReq = {
  ...BaseRequest,
  Username: '',
  Password: '',
  FirstName: '',
  LastName: '',
  Status: 0,
  TypeID: '',
  Noc: '',
  Photo: '',
}

export const EmployeeChangeStatusReq = {
  ...BaseRequest,
  status: 0,
  id: '',
}

export const listEmployeeReq = {
  ...ListBase,
  filter: {
    Name: '',
    SoHoID: '',
    HeHoID: '',
    HesegID: '',
    BagID: '',
    Status: '',
  },
}
//#endregion employee

//#region Privilege
export const PrivilegeGroupAddReq = {
  ...BaseRequest,
  mPriv_Group: {
    PrivGroupID: '',
    PrivGroupName: '',
    IsUserGroup: '',
  },
}

export const PrivilegeGroupMoreReq = {
  ...BaseRequest,
  mPriv_Group: {},
}

export const PrivilegeGroupDeleteReq = {
  ...BaseRequest,
  mPriv_Group: {},
}

export const PrivilegeAddReq = {
  ...BaseRequest,
  priv: {
    privNo: '',
    privName: '',
  },
}

export const PrivilegeDeleteReq = {
  ...BaseRequest,
  mPriv: {},
}

export const PrivilegeUserListReq = {
  ...BaseRequest,
  privNo: '',
}

export const GetEmployeeByIdReq = {
  ...BaseRequest,
  EmployeeID: '',
}

export const PrivilegeMP_GroupUserAddReq = {
  ...BaseRequest,
  userKey: '',
  mPriv_Groups: [{privGroupId: null}],
}

export const AddMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: '',
  mPrivs: [],
}

export const GetMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: '',
}

export const ListMPriv_GroupPrivReq = {
  ...BaseRequest,
  MPriv_Group: {},
}
//#endregion

//#region Log
export const LogActionReq = {
  ...ListBase,
  filter: {
    Controller: '',
    Action: '',
    UserID: '',
    BeginDate: '',
    EndDate: '',
  },
}

export const LogErrorReq = {
  ...ListBase,
  filter: {
    Controller: '',
    Error: '',
    UserID: '',
    BeginDate: '',
    EndDate: '',
  },
}
//#endregion

//#region Search
export const SearchListReq = {
  ...ListBase,
  filter: {
    uid: '',
    result_count: '',
  },
}

//#endregion

//#region Flipbook
export const FlipbookListReq = {
  ...ListBase,
  filter: {
    flipbook_type: '',
    status: '',
    uid: '',
  },
}
export const FlipbookGetReq = {
  ...BaseRequest,
  flipbook: {
    mac: '',
    id: '',
  },
}
export const FlipbookMoreReq = {
  ...ListBase,
  filter: {
    flipbookid: '',
    start_at: '',
    end_at: '',
  },
}
export const FlipbookEditNameReq = {
  ...BaseRequest,
  qr: {
    name: '',
    id: '',
  },
}
export const FlipbookEditUrlReq = {
  ...BaseRequest,
  qr: {
    qr_url: '',
    id: '',
  },
}
export const FlipbookStatListReq = {
  ...ListBase,
  filter: {
    flipbook: {
      id: '',
    },
  },
}

//#endregion

//#region Dashboard
export const DashboardNotifiWorkProjectReq = {
  ...BaseRequest,
  userid: '',
}

export const DashboardAsuudalBatalgaajaaguiReq = {
  ...BaseRequest,
  filter: {
    is_confirm: '',
  },
}

export const DashboardUserbyMounthReq = {
  ...BaseRequest,
  filter: {
    year: '',
    type: '',
  },
}

export const DashboardTopAsuudalReq = {
  ...BaseRequest,
  filter: {
    limit: 5,
  },
}

export const DashboardLastCancelledReq = {
  ...BaseRequest,
  type: '',
}

export const DashboardByUserReq = {
  ...BaseRequest,
  filter: {
    service_id: '',
    year: '',
    mounth: '',
  },
}

export const DashboardToonMedeelelReq = {
  ...BaseRequest,
  filter: {
    created_by: '',
  },
}

export const DashboardByStatusReq = {
  ...BaseRequest,
  filter: {
    customer_id: '',
  },
}

export const DashboardByOrderReq = {
  ...BaseRequest,
  filter: {
    year: '',
    month: '',
  },
}

export const DashboardByBorluulaltByDayReq = {
  ...BaseRequest,
  filter: {
    year: '',
    month: '',
    day: '',
    customer_id: '',
  },
}
//#endregion

//#region News
export const NewsListReq = {
  ...ListBase,
  filter: {
    title: '',
    lang: '',
    category: '',
  },
}

export const NewsAddReq = {
  ...BaseRequest,
  news: {
    title: '',
    lang: '',
    category: 0,
    desc: '',
    image_link: '',
  },
}

export const NewsChangeImageReq = {
  ...BaseRequest,
  news: {
    old_image_link: '',
    image_link: '',
    id: '',
  },
}

export const NewsUpdateReq = {
  ...BaseRequest,
  News: {
    title: '',
    desc: '',
    lang: '',
    category: '',
    id: '',
  },
}

export const NewsChangeStatusReq = {
  ...BaseRequest,
  News: {
    status: 0,
    id: '',
  },
}

export const NewsContentAddReq = {
  ...BaseRequest,
  News: {
    rel_id: '',
    type: '',
    body: '',
  },
}

export const NewsContentUpdateReq = {
  ...BaseRequest,
  News: {
    id: '',
    body: '',
  },
}

export const NewsContentGetReq = {
  ...BaseRequest,
  News: {
    rel_id: '',
    type: '',
  },
}
//#endregion

//#region print
export const PrintboxOrderAddreq = {
  ...BaseRequest,
  order: {
    type: '',
    height: '',
    width: '',
    length: '',
    price: '',
    status: '',
    uid: '',
    payment: '',
    bg: '',
  },
}

export const PrintboxOrderDesignAddreq = {
  ...BaseRequest,
  design: {
    oid: '',
    side: '',
    design: '',
  },
}

export const PrintboxorderListreq = {
  ...ListBase,
  filter: {
    type: '',
    status: '',
    uid: '',
    payment: '',
    year: '',
    month: '',
    day: '',
  },
}

export const PrintcardorderListreq = {
  ...ListBase,
  filter: {
    type: '',
    status: '',
    uid: '',
    payment: '',
    year: '',
    month: '',
    day: '',
    tid:""
  },
}

export const PrintboxorderChangeStatusreq = {
  ...BaseRequest,
  order: {
    status: '',
    id: '',
  },
}
export const PrintboxOrderDesignListreq = {
  ...ListBase,
  filter: {
    type: '',
    status: '',
    oid: '',
  },
}
export const RGBtoCMYKreq = {
  ...ListBase,
  id: '',
}

export const printTtemplateListBackReq = {
  ...ListBase,
  filter: {
    name: '',
    orentation: '',
    size: '',
    status: '',
  },
}
export const printTtemplateBackChangeStatusReq = {
  ...BaseRequest,
  design: {
    status: '',
    id: '',
  },
}
export const printTtemplateBackUpdateReq = {
  ...ListBase,
  design: {
    name: '',
    orentation: '',
    size: '',
    id:''
  },
}

export const printTtemplatelistWithStylesReq = {
  ...ListBase,
  filter: {
    name: '',
    category: '',
    status: '',
    created_by: '',
    orentation: '',
    logo: '',
    qr: '',
    size: '',
    org_rd:""
  },
}
export const printTemplateEditReq = {
  ...ListBase,
  design: {
    name: '',
    category: '',
    logo: '',
    qr: '',
    size: '',
    orentation: '',
    id: '',
  },
}
export const PrintTemplateConnectOrgReq = {
  ...BaseRequest,
  design: {
    org_rd: '',
    id: '',
  },
}

export const printElementEditReq = {
  ...ListBase,
  element: {
    type: '',
    category: '',
    id: '',
  },
}

//#endregion

//#region Pages
export const PagesListReq = {
  ...ListBase,
  filter: {
    pageid: '',
    title: '',
  },
}

export const PagesAddReq = {
  ...BaseRequest,
  page: {
    title: '',
    pageid: '',
  },
}

export const PagesUpdateReq = {
  ...BaseRequest,
  page: {
    title: '',
    pageid: '',
    id: '',
  },
}

export const PagesContentAddReq = {
  ...BaseRequest,
  page: {
    pageid: '',
    lang: '',
    type: '',
    body: '',
  },
}

export const PagesContentUpdateReq = {
  ...BaseRequest,
  page: {
    id: '',
    pageid: '',
    lang: '',
    body: '',
  },
}

export const PagesContentGetReq = {
  ...BaseRequest,
  page: {
    pageid: '',
    lang: '',
  },
}
//#endregion

//#region Videos
export const VideosListReq = {
  ...ListBase,
  filter: {
    title: '',
    lang: '',
    category: '',
    order: false,
  },
}

export const VideosAddReq = {
  ...BaseRequest,
  video: {
    title: '',
    lang: '',
    category: 0,
    desc: '',
    image_link: '',
    youtube_id: '',
  },
}

export const VideosUpdateReq = {
  ...BaseRequest,
  video: {
    title: '',
    desc: '',
    lang: '',
    category: '',
    youtube_id: '',
    id: '',
  },
}

export const VideosChangeStatusReq = {
  ...BaseRequest,
  video: {
    status: 0,
    id: '',
  },
}

export const VideoChangeImageReq = {
  ...BaseRequest,
  video: {
    image_link: '',
    old_image_link: '',
    id: '',
  },
}
//#endregion

//#region Banner
export const BannerListReq = {
  ...ListBase,
  filter: {
    position: '',
    tolov: '',
  },
}

export const BannerAddReq = {
  ...BaseRequest,
  banner: {
    position: '',
    image_link: '',
    tolov: '',
    button_name: '',
    button_link: '',
  },
}

export const BannerUpdateReq = {
  ...BaseRequest,
  banner: {
    position: '',
    image_link: '',
    tolov: '',
    button_name: '',
    button_link: '',
    id: '',
  },
}

export const BannerUpdateTolovReq = {
  ...BaseRequest,
  banner: {
    tolov: '',
    id: '',
  },
}
//#endregion

//#region ProductContent
export const ProductContentAddReq = {
  ...BaseRequest,
  content: {
    content: '',
    type: '',
    relation_id: '',
    id: '',
  },
}

export const ProductContentGetReq = {
  ...BaseRequest,
  content: {
    type: '',
    relation_id: '',
  },
}
//#endregion

//#region User
export const UserListReq = {
  ...ListBase,
  filter: {
    mobile: '',
    id: '',
    delguur: '',
    city_id: '',
    district_id: '',
    khoroo_id: '',
  },
}

export const UserSungahReq = {
  ...BaseRequest,
  customer: {
    id: '',
    active_at: '',
  },
}

export const UserAddReq = {
  ...BaseRequest,
  customer: {
    delguur: '',
    mobile: '',
    pin: '',
  },
}

export const UserUpdateReq = {
  ...BaseRequest,
  customer: {
    delguur: '',
    mobile: '',
    pin: '',
    id: '',
  },
}
//#endregion

//#region Product
export const ProductPriceListReq = {
  ...ListBase,
  filter: {
    product_id: '',
    created_by: '',
    city_id: '',
    district_id: '',
    khoroo_id: '',
    year: '',
    month: '',
  },
}

export const ProductListReq = {
  ...ListBase,
  filter: {
    name: '',
    barcode: '',
  },
}

export const ProductAddReq = {
  ...BaseRequest,
  product: {
    name: '',
    barcode: '',
    price: '',
  },
}

export const ProductUpdateReq = {
  ...BaseRequest,
  product: {
    name: '',
    barcode: '',
    price: '',
    id: '',
  },
}
//#endregion

//#region DTemplete
export const DTempleteTagsUpdateReq = {
  ...BaseRequest,
  desgin: {
    tags: '',
    id: '',
  },
}

export const DTempleteAddReq = {
  ...BaseRequest,
  desgin: {
    name: '',
    template: '',
    preview: '',
    status: 0,
    category: '',
    sub_category: '',
    created_by: '',
  },
}

export const DTempleteUpdateReq = {
  ...BaseRequest,
  desgin: {
    name: '',
    category: '',
    sub_category: '',
    template: '',
    old_preview: '',
    preview: '',
    id: '',
  },
}

export const DTempleteUpdateInfoReq = {
  ...BaseRequest,
  desgin: {
    name: '',
    category: '',
    sub_category: '',
    price: '',
    temdeglelt_odor: '',
    level: '',
    id: '',
  },
}

export const DTempleteChangeStatusReq = {
  ...BaseRequest,
  desgin: {
    status: '',
    id: '',
  },
}

export const DTempleteListReq = {
  ...ListBase,
  desgin: {
    id: '',
    skey: '',
    name: '',
    status: '',
    category: '',
    sub_category: '',
    segment: '',
    created_by: '',
  },
}

export const DTemplateMakeReq = {
  ...ListBase,
  filter: {
    tid: '',
    did: '',
    uid: '',
    type: '',
  },
}

export const DTemplateChangeOrgReq = {
  ...BaseRequest,
  desgin: {
    org_id: '',
    id: '',
  },
}
//#endregion DTemplete

//#region DUpload
export const DUploadAddReq = {
  ...BaseRequest,
  dupload: {
    is_public: '',
    created_by: '',
  },
}

export const DUploadListReq = {
  ...ListBase,
  dupload: {
    is_public: '',
    name: '',
    created_by: '',
  },
}

export const DImageUpdateInfoReq = {
  ...ListBase,
  dupload: {
    is_public: '',
    name: '',
    id: '',
  },
}
//#endregion DTemaplete

//#region DFonts
export const DFontsAddReq = {
  ...BaseRequest,
  font: {
    family: '',
    created_by: '',
  },
}

export const DFontsChangeStatusReq = {
  ...BaseRequest,
  font: {
    status: '',
    id: '',
  },
}

export const DFontsListReq = {
  ...ListBase,
  filter: {
    family: '',
    created_by: '',
    skey: '',
    status: '',
    category: '',
    sub_category: '',
  },
}
//#endregion DFonts

//#region DElement
export const DElementChangeStatusReq = {
  ...BaseRequest,
  element: {
    status: '',
    id: '',
  },
}

export const DElementChangeBBStatusReq = {
  ...BaseRequest,
  element: {
    level: '',
    id: '',
  },
}

export const DElementTagsUpdateReq = {
  ...BaseRequest,
  element: {
    tags: '',
    id: '',
  },
}

export const DElementAddReq = {
  ...BaseRequest,
  element: {
    name: '',
    template: '',
    is_public: '',
    preview: '',
    created_by: '',
  },
}

export const DElementListReq = {
  ...ListBase,
  filter: {
    skey: '',
    name: '',
    category: '',
    sub_category: '',
    created_by: '',
    is_public: '',
  },
}

export const DElementUpdateInfoReq = {
  ...ListBase,
  element: {
    name: '',
    is_public: '',
    category: '',
    sub_category: '',
    price: '',
    level: '',
    id: '',
  },
}
//#endregion DElement

//#region DCollection
export const DCollectionChangeStatusReq = {
  ...BaseRequest,
  collection: {
    status: '',
    id: '',
  },
}

export const DCollectionChangeImageReq = {
  ...BaseRequest,
  collection: {
    image: '',
    old_image: '',
    id: '',
  },
}

export const DCollectionAddReq = {
  ...BaseRequest,
  collection: {
    name: '',
    description: '',
    status: '',
    image: '',
    templates: '',
  },
}

export const DCollectionListReq = {
  ...ListBase,
  filter: {
    skey: '',
    name: '',
    status: '',
  },
}

export const DCollectionUpdateReq = {
  ...ListBase,
  collection: {
    name: '',
    description: '',
    status: '',
    image: '',
    templates: '',
    id: '',
  },
}
//#endregion DElement

//#region DTextStyles
export const DTextStylesListReq = {
  ...ListBase,
  desgin: {
    status: 0,
    tags: '',
    skey: '',
  },
}

export const DTextStylesChangeStatusReq = {
  ...ListBase,
  desgin: {
    status: 0,
    id: '',
  },
}
//#endregion

//#region Designers
export const DesignerChangePassReq = {
  ...BaseRequest,
  designerID: '',
  newPassword: '',
  OLdPass: '',
  IsAdmin: false,
  realOldPass: '',
}
export const DesignerListReq = {
  ...ListBase,
  filter: {
    name: '',
    mobile: '',
    email: '',
    is_designer: '',
    provider: '',
    id: '',
    org_id: '',
  },
}
export const DesignerAddReq = {
  ...BaseRequest,
  designer: {
    mobile: '',
    email: '',
    name: '',
    image: '',
    password: '',
    is_designer: '',
    provider: '',
  },
}
export const DesignerChangeOrgReq = {
  ...BaseRequest,
  designer: {
    org_id: '',
    id: '',
  },
}
export const DesignerUpdateReq = {
  ...BaseRequest,
  designer: {
    mobile: '',
    email: '',
    name: '',
    is_designer: '',
    provider: '',
    id: '',
  },
}
export const DesignerBanReq = {
  ...BaseRequest,
  designer: {
    id: '',
    level: '',
  },
}
export const DesignerUpdateLevelReq = {
  ...BaseRequest,
  designer: {
    level: '',
    id: '',
  },
}
export const DesignerDesignerlistReq = {
  ...ListBase,
  filter: {
    uid: '',
  },
}
//#endregion DElement

//#region Org
export const OrgListReq = {
  ...ListBase,
  filter: {
    name: '',
    umch: '',
    status: '',
    register: '',
  },
}
export const OrgAddReq = {
  ...BaseRequest,
  org: {
    name: '',
    url: '',
    logo: '',
    register: '',
    umch: '',
  },
}
export const OrgUpdateReq = {
  ...BaseRequest,
  org: {
    name: '',
    url: '',
    register: '',
    umch: '',
    id: '',
  },
}
export const OrgChangelogoReq = {
  ...BaseRequest,
  org: {
    id: '',
    logo: '',
    old_logo: '',
  },
}
export const OrgChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}

export const OrgLogosListReq = {
  ...ListBase,
  filter: {
    status: '',
    logo_type: '',
    lang: '',
    position: '',
    org_id: '',
    color_type: '',
  },
}
export const OrgLogosAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    logo: '',
    position: '',
    lang: '',
    status: '',
    logo_type: '',
    color_type: '',
  },
}
export const OrgLogosUpdateReq = {
  ...BaseRequest,
  org: {
    position: '',
    lang: '',
    logo_type: '',
    color_type: '',
    id: '',
  },
}
export const OrgLogosChangelogoReq = {
  ...BaseRequest,
  org: {
    id: '',
    logo: '',
    old_logo: '',
  },
}
export const OrgLogosChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}

export const OrgColorsListReq = {
  ...ListBase,
  filter: {
    org_id: '',
    type: '',
  },
}
export const OrgColorsAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    color: '',
    color_type: '',
  },
}
export const OrgColorsUpdateReq = {
  ...BaseRequest,
  org: {
    color: '',
    color_type: '',
    id: '',
  },
}

export const OrgFontsListReq = {
  ...ListBase,
  filter: {
    org_id: '',
  },
}
export const OrgFontsAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    font_id: '',
  },
}
export const OrgFontsUpdateReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    font_id: '',
    id: '',
  },
}

export const OrgElementsListReq = {
  ...ListBase,
  filter: {
    org_id: '',
    category: '',
  },
}
export const OrgElementsAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    element_id: '',
    category: '',
  },
}
export const OrgElementsUpdateReq = {
  ...BaseRequest,
  org: {
    element_id: '',
    category: '',
    id: '',
  },
}

export const OrgPptListReq = {
  ...ListBase,
  filter: {
    org_id: '',
  },
}
export const OrgPptChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}
export const OrgPptUpdateReq = {
  ...BaseRequest,
  org: {
    name: '',
    version: '',
    id: '',
  },
}
export const OrgPptAddImageReq = {
  ...BaseRequest,
  org: {
    id: '',
    preview: '',
    old_preview: '',
  },
}

export const OrgPdfListReq = {
  ...ListBase,
  filter: {
    org_id: '',
  },
}
export const OrgPdfChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}
export const OrgPdfUpdateReq = {
  ...BaseRequest,
  org: {
    name: '',
    version: '',
    id: '',
  },
}
export const OrgPdfAddImageReq = {
  ...BaseRequest,
  org: {
    id: '',
    preview: '',
    old_preview: '',
  },
}
//#endregion Org

//#region DWallet_History
export const DWallet_HistoryListReq = {
  ...ListBase,
  filter: {
    wallet_id: '',
    month: '',
    year: '',
    day: '',
  },
}

export const DWallet_HistoryAddReq = {
  ...BaseRequest,
  wallet: {
    wallet_id: '',
    price: 0,
    walletPrev: 0,
  },
}
//#endregion DElement

//#region Subscription
export const SubscriptionAddReq = {
  ...BaseRequest,
  order: {
    uid: '',
    bagts: '',
    sungasan_sar: '',
    bagts_hugatsaa: '',
    payment: '',
    amount: '',
    status: '',
    org_rd: '',
    school_id: null,
    ebarimt_email: '',
  },
}
export const SubscriptionListReq = {
  ...ListBase,
  filter: {
    uid: '',
    bagts: '',
    status: '',
    payment: '',
    year: '',
    month: '',
    day: '',
    org_rd: '',
    ebarimt_email: '',
    ebarimt_ilgeesen: '',
  },
}
//#endregion Subscription

//#region School
export const SchoolListReq = {
  ...ListBase,
  filter: {
    name: '',
    umch: '',
    angilal: '',
    register: '',
    address_city: '',
    address_district: '',
  },
}
//#endregion School

//#region LetterBanner
export const LetterBannerListReq = {
  ...ListBase,
  filter: {
    pid: '',
    relation_type: '',
    uid: '',
    payment: '',
    year: '',
    month: '',
    day: '',
  },
}
export const LetterBannerUpdateReq = {
  ...BaseRequest,
  order: {
    id: '',
    tolov: '',
    ug: '',
  },
}
//#endregion

//#region Feedback
export const FeedbackListReq = {
  ...ListBase,
  filter: {
    main_type: '',
    rel_type: '',
    rel_id: '',
    desc: '',
    attach: '',
    uid: '',
    uname: '',
    uphone: '',
  },
}

//#endregion

//#region QR
export const QRAddReq = {
  ...BaseRequest,
  qr: {
    name: '',
    qr_type: '',
    qr_url: '',
    uid: '',
  },
}

export const QRTravelUpdateReq = {
  ...BaseRequest,
  qr: {
    map: '',
    city: '',
    district: '',
    category: '',
    long: '',
    lati: '',
    id: '',
  },
}

export const QRTravelRouteAddReq = {
  ...BaseRequest,
  route: {
    city: '',
    district: '',
    name: '',
    color: '',
    cover: '',
    uid: '',
  },
}
export const QRTravelRouteUpdateReq = {
  ...BaseRequest,
  route: {
    city: '',
    district: '',
    name: '',
    color: '',
    id: '',
  },
}
export const QRTravelRouteChangemapReq = {
  ...BaseRequest,
  route: {
    map: '',
    old_map: '',
    id: '',
  },
}
export const QRTravelRouteChangecoverReq = {
  ...BaseRequest,
  route: {
    cover: '',
    old_cover: '',
    id: '',
  },
}

export const QRCodeAddReq = {
  ...BaseRequest,
  qr: {
    qr_code: '',
    id: '',
  },
}
export const QRListReq = {
  ...ListBase,
  filter: {
    qr_type: '',
    status: '',
    uid: '',
  },
}
export const QRTravelRouteListReq = {
  ...ListBase,
  filter: {
    city: '',
    district: '',
    status: '',
    uid: '',
  },
}

export const QRTravelRouteChangestatusReq = {
  ...BaseRequest,
  route: {
    status: '',
    id: '',
  },
}
export const QRTravelRoutePointaddReq = {
  ...BaseRequest,
  route: {
    points: [],
    id: '',
  },
}
export const QRTravelRoutePointUpdateReq = {
  ...BaseRequest,
  point: {
    daraalal: '',
    name: '',
    distance: '',
    id: '',
    tid: '',
    rid: '',
  },
}
export const editQRTravelLangReq = {
  ...BaseRequest,
  qr: {
    id: '',
    url: '',
  },
}

export const addQRTravelLangReq = {
  ...BaseRequest,
  qr: {
    id: '',
    langs: [],
  },
}

export const QRGetReq = {
  ...BaseRequest,
  qr: {
    mac: '',
    id: '',
  },
}
export const QRMoreReq = {
  ...ListBase,
  filter: {
    qrid: '',
    start_at: '',
    end_at: '',
  },
}
export const QREditNameReq = {
  ...BaseRequest,
  qr: {
    name: '',
    id: '',
  },
}
export const QREditUrlReq = {
  ...BaseRequest,
  qr: {
    qr_url: '',
    id: '',
  },
}
export const QRStatListReq = {
  ...ListBase,
  filter: {
    qr: {
      id: '',
    },
  },
}
export const QRTravelRoutePointListReq = {
  ...ListBase,
  filter: {
    rid: '',
  },
}
export const QRTravelListReq = {
  ...ListBase,
  filter: {
    qr_type: '',
    status: '',
    uid: '',
    category: '',
    name: '',
  },
}
export const QRTravelImagesListReq = {
  ...ListBase,
  filter: {
    tid: '',
  },
}

export const QRTravelImagesAddReq = {
  ...BaseRequest,
  travel: {
    tid: '',
    image: '',
  },
}

export const QRTravelTextAddReq = {
  ...BaseRequest,
  text: {
    tid: '',
    text: '',
    lang: '',
  },
}
export const QRTravelTextGetItemReq = {
  ...BaseRequest,
  text: {
    tid: '',
    lang: '',
  },
}
export const QRTravelTextUpdateReq = {
  ...BaseRequest,
  text: {
    id: '',
    text: '',
  },
}

export const QRTravelDeletemp3Req = {
  ...BaseRequest,
  qr: {
    id: '',
  },
}
export const QRTravelText2AudioReq = {
  ...BaseRequest,
  travel: {
    id: '',
    lang: '',
    text: '',
    old_audio_url: '',
  },
}
//#endregion QR

//#region Event

export const EventAddReq = {
  ...BaseRequest,
  event: {
    name: '',
    desc: '',
    preview: '',
    uid: '',
  },
}
export const EventRegistrationReq = {
  ...BaseRequest,
  event_user: {
    id: '',
    status: '',
  },
}
export const EventListReq = {
  ...ListBase,
  filter: {
    uid: '',
  },
}
export const EventUserAddReq = {
  ...BaseRequest,
  event_id: '',
  event_users: [],
}
export const EventUserListReq = {
  ...ListBase,
  filter: {
    event_id: '',
    send_email: '',
    status: '',
  },
}
export const EventIrtsReq = {
  ...ListBase,
  filter: {
    event_id: '',
  },
}
export const EventUserUpdateReq = {
  ...BaseRequest,
  event_user: {
    name: '',
    email: '',
    type_name: '',
    type_color: '',
    id: '',
  },
}
export const EventSentMailReq = {
  ...BaseRequest,
  email: {
    from: '',
    replyto: '',
    to: '',
    subject: '',
    body: '',
    invitation: '',
    agenda: '',
    userid: '',
  },
}
export const EventEmailStatusUpdateReq = {
  ...BaseRequest,
  event_user: {
    id: '',
    send_email: '',
  },
}
//#endregion Event

//#region namecard
export const printElementListReq = {
  ...ListBase,
  filter: {
    status: '',
    type: '',
    category: '',
    uid: '',
  },
}

export const printBackgroundListReq = {
  ...ListBase,
  filter: {
    status: '',
    type: '',
    category: '',
    id: '',
    url: '',
    created_at: '',
    created_by: '',
    name: '',
  },
}

export const printPhotosListReq = {
  ...ListBase,
  filter: {
    status: '',
    type: '',
    category: '',
    id: '',
    url: '',
    created_at: '',
  },
}
export const templateChangeStatusReq = {
  ...BaseRequest,
  design: {
    status: '',
    id: '',
  },
}
export const BackgroundChangeStatusReq = {
  ...BaseRequest,
  background: {
    status: '',
    id: '',
  },
}

export const PrintTemplateConnectbackReq = {
  ...BaseRequest,
  design: {
    back_id: '',
    id: '',
  },
}

export const ElementChangeStatusReq = {
  ...BaseRequest,
  element: {
    status: '',
    id: '',
  },
}
export const printElementCollectionAddReq = {
  ...ListBase,
  collection: {
    name: '',
    created_by: '',
  },
}
export const printElementCollectionListReq = {
  ...ListBase,
  filter: {
    name: '',
    status: '',
  },
}
export const printElementCollectionUpdateReq = {
  ...ListBase,
  collection: {
    name: '',
    id: '',
  },
}
export const printElementCollectionConnectReq = {
  ...ListBase,
  collection: {
    cid: '',
    eid: '',
    url: '',
  },
}
export const printElementCollectionConnectlistReq = {
  ...ListBase,
  filter: {
    cid: '',
    eid: '',
  },
}

export const printElementCollectionchangestatusReq = {
  ...ListBase,
  collection: {
    status: '',
    id: '',
  },
}
export const printElementCollectionDeleteReq = {
  ...ListBase,
  collection: {
    status: '',
    id: '',
  },
}

//#endregion namecard
