import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {dateTimeFormat, isNullOrEmpty} from '../../../../services/Utils'
import {
  BaseRequest,
} from '../../../../services/Request'
import {CDN_URL, PrintTemplateGet} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {TemplateBack} from './back'
import CardOrder from '../../orders/card'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Template',
    path: '/templates',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: any
}

const TemplateMore: FC<Props> = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('image')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [validate, setValidate] = useState<any>([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [pNumber, setPNumber] = useState('')
  const [provider, setProvider] = useState('')
  const [isDesigner, setIsDesigner] = useState('')
  const [count, setCount] = useState(0)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const side = queryParams.get('side')

  useEffect(() => {
    get()
  }, [id, count])

  useEffect(() => {
    if (side) {
      setTab(side)
    }
  }, [side])

  const get = () => {
    setData('')
    if (!loading) {
      setLoading(true)
    }
    PrintTemplateGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
            <span style={{visibility: 'hidden'}}></span>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{t('common:USERS.activate')}</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                {t('common:USERS.activate')}
              </span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                placeholder='name'
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                value={email}
                placeholder='email'
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateChange('email', e)
                }}
              ></input>
              {validate['email'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='mobile'
                onChange={(e) => {
                  setPNumber(e.target.value)
                  validateChange('pNumber', e)
                }}
                value={pNumber}
              ></input>
              {validate['pNumber'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setProvider(e.target.value)
                  validateChange('provider', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='provider'
                id=''
                value={provider}
              >
                <option value=''>{t('common:validSelect')}</option>
                <option value='google'>{t('common:USERS.google')}</option>
                <option value='facebook'>{t('common:USERS.facebook')}</option>
                <option value='grado'>{t('common:USERS.grado')}</option>
                <option value='web'>{t('common:USERS.web')}</option>
                <option value='admin'>{t('common:USERS.admin')}</option>
              </select>
              {validate['provider'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setIsDesigner(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='status'
                value={isDesigner}
                id=''
              >
                <option value={''}>{t('common:validSelect')}</option>

                <option value={'1'}>{t('common:USERS.designer')}</option>
                <option value={'0'}>{t('common:USERS.notDesigner')}</option>
              </select>
              {validate['isDesigner'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='pt-10' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                // onClick={isNullOrEmpty(data) ? save : update}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{id}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header card-header-overflow border-1'>
          <div className='flex gap-8 w-full card-header-flex'>
            <div className='flex gap-20 py-6' style={{fontSize: '14px', fontWeight: '500'}}>
              {!isNullOrEmpty(data) ? (
                <div className='flex items-center gap-4'>
                  <img
                    style={{
                      objectFit: 'contain',
                      height: '100px',
                      border: '1px solid #ecf0f1',
                    }}
                    src={CDN_URL + data.styles[0].preview}
                    alt=''
                  />
                </div>
              ) : null}
              <div style={{minWidth: '250px'}} className='flex gap-5'>
                <i
                  style={{fontSize: '20px', paddingTop: '4px'}}
                  className='fa-regular fa-address-card'
                ></i>
                <div style={{color: 'gray'}} className='flex flex-col'>
                  <span>
                    Нэр: <span style={{color: 'black'}}>{data.name}</span>
                  </span>
                  <span>
                    Босоо, Хөндлөн:{' '}
                    <span style={{color: 'black'}}>
                      {data.orentation === 'l' ? 'Босоо' : 'Хөндлөн'}
                    </span>
                  </span>
                  <span>
                    QR тай эсэх:{' '}
                    <span style={{color: 'black'}}>{data.qr === 1 ? 'Тийм' : 'Үгүй'}</span>
                  </span>
                  <span>
                    Логотой эсэх:{' '}
                    <span style={{color: 'black'}}>{data.logo === 1 ? 'Тийм' : 'Үгүй'}</span>
                  </span>
                  <span>
                    Огноо: <span style={{color: 'black'}}>{dateTimeFormat(data.created_at)}</span>
                  </span>
                </div>
              </div>
              <div className='flex gap-5'>
                <div className='flex items-center gap-3' style={{height: 'fit-content'}}>
                  <i
                    style={{fontSize: '20px', paddingTop: '4px'}}
                    className='fa-solid fa-layer-group'
                  ></i>
                  <span>Категори:</span>
                </div>

                <div className='flex flex-col gap-1'>
                  <span>
                    <div style={{color: 'black', display: 'flex', flexWrap: 'wrap', gap: '4px'}}>
                      {!isNullOrEmpty(data.category)
                        ? data.category
                            .split('-')
                            .filter((num: any) => num)
                            .map((num: any) => Math.abs(parseInt(num)))
                            .map((el: any) => {
                              return <span>{t(`common:magicnum.nameCardCategory.${el}`)};</span>
                            })
                        : null}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'image',
                })}
                onClick={() => setTab('image')}
                role='tab'
              >
                Өнгөнүүд
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'order',
                })}
                onClick={() => setTab('order')}
                role='tab'
              >
                Захиалгууд
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'feedback',
                })}
                onClick={() => setTab('feedback')}
                role='tab'
              >
                feedback
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'tags',
                })}
                onClick={() => setTab('tags')}
                role='tab'
              >
                Түлхүүр үг
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'back',
                })}
                onClick={() => setTab('back')}
                role='tab'
              >
                Арын загвар
              </div>
            </li>
          </ul>
        </div>
        <form className='form'>
          {tab === 'image' ? (
            <div className='card-body py-6'>
              <div className='flex flex-col gap-2'>
                {!isNullOrEmpty(data)
                  ? data?.styles.map((el: any) => {
                      return (
                        <div key={el.pid} className='flex items-center gap-4 px-6'>
                          <div className='flex items-center gap-8'>
                            <div style={{height: '180px'}}>
                              <img
                                style={{
                                  objectFit: 'contain',
                                  height: '180px',
                                  border: '1px solid #ecf0f1',
                                }}
                                src={CDN_URL + el.preview}
                                alt=''
                              />
                            </div>
                          </div>
                          <div>
                            {el.color.split(',').map((el: any) => {
                              return (
                                <div className='flex gap-3 items-center'>
                                  <div
                                    style={{height: '30px', width: '50px', backgroundColor: el}}
                                  ></div>
                                  <span>{el}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
          ) : tab === 'order' ? (
            <CardOrder tid={data.id} />
          ) : tab === 'back' ? (
            <TemplateBack back={data} setCount={setCount} />
          ) : null}
        </form>
      </div>
    </>
  )
}

export {TemplateMore}
