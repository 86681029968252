import {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {isNullOrEmpty} from '../../../../services/Utils'
import {
  DesignerAdd,
  DesignerUpdate,
  printTemplateBackUpdate,
  printTemplateEdit,
} from '../../../../services/main'
import {
  DesignerAddReq,
  DesignerUpdateReq,
  magicnum,
  printTemplateEditReq,
  printTtemplateBackUpdateReq,
} from '../../../../services/Request'
import {use} from 'i18next'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const EditBackTemplate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [size, setSize] = useState('')
  const [orientation, setOrientation] = useState('')
  const [validate, setValidate] = useState<any>([])
  console.log(item.orentation)

  useEffect(() => {
    setName(item?.name)
    setSize(item?.size)
    setOrientation(item?.orentation)
  }, [item])

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = printTtemplateBackUpdateReq
      req.design.name = name
      req.design.size = size
      req.design.orentation = orientation
      req.design.id = item.id
      printTemplateBackUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-4 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Загвар засах</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>Загвар засах</span>
        </div>
        <div className='flex gap-12'>
          <div className='flex flex-col gap-2'>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                placeholder={t('common:USERS.name')}
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setSize(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='size'
                id=''
                value={size}
              >
                <option value='st'>стандарт</option>
                <option value='stp'>стандарт бус</option>
              </select>
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setOrientation(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                value={orientation}
                id=''
              >
                <option value='l'>Хөндлөн</option>
                <option value='p'>Босоо</option>
              </select>
            </div>
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EditBackTemplate}
