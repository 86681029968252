import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, getUser, isNullOrEmpty} from '../../../../services/Utils'
import CustomModal from '../../../modules/CustomModal/modal'
import {
  BaseRequest,
  DesignerDesignerlistReq,
  printTtemplateBackChangeStatusReq,
  printTtemplateListBackReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  DesignerDesignerlist,
  printTtemplateBackChangeStatus,
  printTtemplateBackDelete,
  printTtemplateListBack,
  printTtemplatelistWithStyles,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {EditBackTemplate} from './edit'
import {PutOrg} from './putOrg'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
  add?: any
}

const BackTemplates: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showFilter, setShowFilter] = useState(false)
  const [name, setName] = useState('')
  const [size, setSize] = useState('')
  const [orentation, setOrentation] = useState('')
  const [status, setStatus] = useState('')
  const [designerData, setDesignerData] = useState([])
  const [editModal, setEditModal] = useState(false)
  const [addOrgModal, setAddOrgModal] = useState(false)

  useEffect(() => {
    designerlist()
    List()
  }, [PID])

  const designerlist = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DesignerDesignerlistReq
    DesignerDesignerlist(req, onDesinerSuccess, onFailed, 'POST')
  }
  const onDesinerSuccess = (response: any) => {
    setLoading(false)
    setDesignerData(response.data)
  }

  const List = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = printTtemplateListBackReq
    req.filter.status = status
    req.filter.orentation = orentation
    req.filter.name = name
    req.filter.size = size
    req.pid = PID
    req.pSize = sizePerPage
    printTtemplateListBack(req, onSuccessList, onListFailed, 'POST')
  }
  const onSuccessList = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onListFailed = (error: any) => {
    setLoading(false)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <span>{cell}</span>
  }
  const infoRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-1'>
        <span>{row.size === 'st' ? 'Стандарт' : 'Стандарт бус'}</span>
        <span>{row.orentation === 'l' ? 'Хэвтээ' : 'Босоо'}</span>
      </div>
    )
  }
  const userRowFormat = (cell: any, row: any) => {
    const userinfo = getUser(designerData, cell) || {name: undefined}
    return <div className='flex items-center gap-4'>{userinfo?.name}</div>
  }
  function actionRowFormat(cell: string, row: any) {
    return props.type === 'holboh' ? (
      <div onClick={() => props.add(row.id)} className='btn btn-primary btn-sm'>
        Холбох
      </div>
    ) : (
      <div style={{maxWidth: '100px', display: 'flex', justifyContent: 'flex-end'}}>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setEditModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Add user to Costumer'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setAddOrgModal(true)
          }}
        >
          {isNullOrEmpty(row.org_rd) ? (
            <i className='fa-solid fa-city fs-5'></i>
          ) : (
            <i className='fa-solid fa-city fs-5' style={{color: 'green'}}></i>
          )}
        </div>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            {type === 'deleteMain' ? (
              <img style={{width: '100px'}} src={CDN_URL + row.url} alt='' />
            ) : null}
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    printTtemplateBackDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    List()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = printTtemplateBackChangeStatusReq
    req.design.id = row.id
    req.design.status = row.status === 1 ? '0' : '1'
    printTtemplateBackChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onFailed = (error: any) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangeStatusSuccess = () => {
    List()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    setOrentation('')
    setStatus('')
    setSize('')
  }

  const imgRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-4'>
        <img style={{height: '100px'}} src={CDN_URL + cell} />
        <span>{row.name}</span>
      </div>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => submit(row, 'status')}
        style={{
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '6px',
          width: '80px',
          backgroundColor: `${cell === 0 ? 'red' : cell === 1 ? 'rgb(76, 209, 55)' : null}`,
          cursor: 'pointer',
          margin: '0 auto',
        }}
      >
        {t(`common:magicnum.Status.${cell}`)}
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },

    headerOtherColumn: [
      {
        title: 'Загвар',
        dataField: 'preview',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Хэрэглэгч',
        dataField: 'created_by',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төрөл',
        dataField: 'type',
        dataSort: false,
        dataFormat: infoRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Статус',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Он сар өдөр',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үйлдэл',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  const onAddSuccess = () => {
    setEditModal(false)
    List()
  }

  return (
    <>
      {editModal ? (
        <CustomModal visible={editModal} closeModal={() => setEditModal(false)} className=''>
          <EditBackTemplate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {addOrgModal ? (
        <CustomModal
          visible={addOrgModal}
          closeModal={() => setAddOrgModal(false)}
          className='VideoModal'
        >
          <PutOrg t={t} item={item} setcustomerAddModal={setAddOrgModal} listDes={List} />
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Арын-загвар</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div onClick={() => List()} className='card-toolbar'>
              <a className='btn btn-sm btn-light-primary'>{t('common:search')}</a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setSize(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='size'
                    value={size}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='st'>Стандарт</option>
                    <option value='stp'>Стандарт бус</option>
                  </select>
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setOrentation(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='orentation'
                    value={orentation}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='l'>Хэвтээ</option>
                    <option value='p'>Босоо</option>
                  </select>
                </div>
                <div className='col '>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='0'>{t('common:magicnum.Status.0')}</option>
                    <option value='1'>{t('common:magicnum.Status.1')}</option>
                  </select>
                </div>
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS:clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Арын загвар</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт {PTotal} арын загвар</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
            key={data.length}
          />
        </div>
      </div>
    </>
  )
}

export {BackTemplates}
