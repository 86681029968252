import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, getUser} from '../../../../services/Utils'
import {
  BaseRequest,
  DesignerDesignerlistReq,
  ElementChangeStatusReq,
  magicnum,
  printElementListReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  DesignerDesignerlist,
  PrintElementChangeStatus,
  PrintElementDelete,
  printElementList,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import CustomModal from '../../../modules/CustomModal/modal'
import {EditElement} from './add'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
  addToCollection?: any
  included?: any
  loadingaddTobagts?: any
}

const NameCardElement: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [designerData, setDesignerData] = useState<any>([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showFilter, setShowFilter] = useState(false)
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState('')
  const [designer, setdesigner] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [type, setType] = useState('')

  useEffect(() => {
    designerlist()
    elementList()
  }, [PID])

  const elementList = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = printElementListReq
    req.filter.status = status
    req.filter.category = category
    req.filter.type = type
    req.filter.uid = designer
    req.pid = PID
    req.pSize = sizePerPage
    printElementList(req, onSuccessElement, onListFailed, 'POST')
  }
  const onSuccessElement = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onListFailed = (error: any) => {
    setLoading(false)
  }

  const designerlist = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DesignerDesignerlistReq
    DesignerDesignerlist(req, onDesinerSuccess, onFailed, 'POST')
  }
  const onDesinerSuccess = (response: any) => {
    setLoading(false)
    setDesignerData(response.data)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <span>{cell}</span>
  }

  const imgRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-2'>
        <div className='flex flex-1 justify-center items-center px-2 w-[10px]'>
          <img style={{height: '100px', maxWidth: '300px'}} src={CDN_URL + cell} />
        </div>
      </div>
    )
  }

  const userRowFormat = (cell: any, row: any) => {
    const userinfo = getUser(designerData, cell) || {name: undefined}
    return <div className='flex justify-center '>{userinfo?.name}</div>
  }

  const typeRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex flex-col items-center'>
        <span style={{fontWeight: '600'}}>
          {t(`common:magicnum.namecardElementCategory.namecard-${row.category}`)}
        </span>
        <span style={{color: 'gray'}}>{t(`common:magicnum.namecardElementType.${row.type}`)}</span>
      </span>
    )
  }
  function actionRowFormat(cell: string, row: any) {
    return props.type === 'nemeh' ? (
      props.included.includes(row.id) ? (
        <div style={{borderRadius:'8px', textAlign:"center", width:'fit-content', padding:"8px", color:"white", fontWeight:"600"}} className='bg-warning'>Багцад байгаа</div>
      ) : (
        <button
          onClick={() => props.addToCollection(row.id, row.url)}
          className='btn btn-sm btn-primary px-2 flex items-center'
          disabled={props.loadingaddTobagts}
        >
          <i className='fa-solid fa-plus'></i>
          <span>Холбох</span>
        </button>
      )
    ) : (
      <div style={{maxWidth: '100px', display: 'flex', justifyContent: 'flex-end'}}>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setEditModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            {type === 'deleteMain' ? (
              <img style={{width: '100px'}} src={CDN_URL + row.url} alt='' />
            ) : null}
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    elementList()
    PrintElementDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    elementList()
    return toast.error(t('common:successDelete'))
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => submit(row, 'status')}
        style={{
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '6px',
          width: '80px',
          backgroundColor: `${cell === 0 ? 'red' : cell === 1 ? 'rgb(76, 209, 55)' : null}`,
          cursor: 'pointer',
          margin: '0 auto',
        }}
      >
        {t(`common:magicnum.Status.${cell}`)}
      </div>
    )
  }

  const changeStatus = (row: any) => {
    var req = ElementChangeStatusReq
    req.element.id = row.id
    req.element.status = row.status === 1 ? '0' : '1'
    PrintElementChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onFailed = (error: any) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangeStatusSuccess = () => {
    elementList()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setCategory('')
    setType('')
    setdesigner('')
    setStatus('')
    setPID(1)
  }
  const onAddSuccess = () => {
    setEditModal(false)
    elementList()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Зураг',
        dataField: 'url',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Дизайнер',
        dataField: 'created_by',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төрөл',
        dataField: 'type',
        dataSort: false,
        dataFormat: typeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Статус',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Он сар өдөр',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үйлдэл',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {editModal ? (
        <CustomModal visible={editModal} closeModal={() => setEditModal(false)} className=''>
          <EditElement t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Элемент</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div
              onClick={() => {
                elementList()
                setPID(1)
              }}
              className='card-toolbar'
            >
              <div className='btn btn-sm btn-light-primary'>{t('common:search')}</div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setdesigner(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='designer'
                    value={designer}
                    id=''
                  >
                    <option value=''>Дизайнер - {t('common:all')}</option>
                    {designerData.map((el: any) => {
                      return <option value={el.id}>{el.name}</option>
                    })}
                  </select>
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='type'
                    value={type}
                    id=''
                  >
                    <option value=''>Төрөл - {t('common:all')}</option>
                    {magicnum.ElementCategory.map((el: any) => {
                      return (
                        <option value={el}>{t(`common:magicnum.namecardElementType.${el}`)}</option>
                      )
                    })}
                  </select>
                </div>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setCategory(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='category'
                    value={category}
                    id=''
                  >
                    <option value=''>Категори - {t('common:all')}</option>
                    {magicnum.ElementSubCategory.filter((i: any) => i.parent === type).map(
                      (el: any) => {
                        return (
                          <option value={el.id}>
                            {t(`common:magicnum.namecardElementCategory.namecard-${el.id}`)}
                          </option>
                        )
                      }
                    )}
                  </select>
                </div>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>Статус - {t('common:all')}</option>
                    {magicnum.status.map((el: any) => {
                      return <option value={el}>{t(`common:magicnum.Status.${el}`)}</option>
                    })}
                  </select>
                </div>
                <div className='col'>
                  <div
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Элемент</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт: {PTotal} элемент</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
            key={props.type === 'nemeh' ? props.included : data?.length}
          />
        </div>
      </div>
    </>
  )
}

export {NameCardElement}
