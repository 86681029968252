import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, getUser} from '../../../../services/Utils'
import {
  BackgroundChangeStatusReq,
  BaseRequest,
  DesignerListReq,
  NewsChangeStatusReq,
  magicnum,
  printBackgroundListReq,
  printElementListReq,
  printTtemplatelistWithStylesReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  DesignerDelete,
  DesignerList,
  NewsChangeStatus,
  PrintBackgroundChangeStatus,
  PrintBackgroundDelete,
  printBackgroundList,
  printElementList,
  printTtemplatelistWithStyles,
} from '../../../../services/main'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
}

const NameCardBackground: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [statusmodal, setStatusmodal] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [provider, setProvider] = useState('')
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')
  const [designerData, setDesignerData] = useState([])
  const [editModal, setEditModal] = useState(false)
  const [createdBy, setCreatedBy] = useState('')
  const [showModalSungalt, setShowModalSungalt] = useState(false)
  const [banShowModal, setBanShowModal] = useState(false)
  const [changePassShowModal, setChangePassShowModal] = useState(false)
  const [orgidModal, setOrgidModal] = useState(false)
  const [newStatus, setNewStatus] = useState('')
  useEffect(() => {
    List()
  }, [PID])

  const List = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = printBackgroundListReq
    req.filter.status = status
    req.filter.id = id
    req.filter.name = name
    req.filter.type = type
    req.pid = PID
    req.pSize = sizePerPage
    printBackgroundList(req, onSuccessBackground, onListFailed, 'POST')
  }
  const onSuccessBackground = (response: any) => {
    setLoading(false)
    console.log(response.data)
    setData(response.data)
  }
  const onListFailed = (error: any) => {
    setLoading(false)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <span>{cell}</span>
  }
  // const typeRowFormat = (cell: any, row: any) => {
  //   return (
  //     <span className='flex flex-col items-center'>
  //       <span style={{fontWeight: '600'}}>
  //         {t(`common:magicnum.namecardBackgroundCategory.namecard-${row.category}`)}
  //       </span>
  //       <span style={{color: 'gray'}}>{t(`common:magicnum.namecardBackgroundType.${row.type}`)}</span>
  //     </span>
  //   )
  // }

  const userRowFormat = (cell: any, row: any) => {
      const userinfo = getUser(designerData, cell) || {name: undefined}
      return <div className='flex items-center gap-4'>{userinfo?.name}</div>
    }
  function actionRowFormat(cell: string, row: any) {
    return (
      <div style={{maxWidth: '100px', display: 'flex', justifyContent: 'flex-end'}}>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setEditModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            {type === 'deleteMain' ?(
              <img style={{width: '100px'}} src={CDN_URL + row.url} alt=''/>
            ) : null}
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    PrintBackgroundDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    List()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = BackgroundChangeStatusReq
    req.background.id = row.id
    req.background.status = row.status === 1 ? '0' : '1'
    PrintBackgroundChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onFailed = (error: any) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangeStatusSuccess = () => {
    List()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    setEmail('')
    setProvider('')
    setStatus('')
    setMobile('')
    setId('')
  }

  const imgRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-1'>
        <div className='flex flex-1 justify-center items-center px-2 w-[30px]'>
          <img style={{height:"100px"}} src={CDN_URL + cell} />
        </div>
      </div>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => submit(row, 'status')}
        style={{
          fontWeight: '500',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '6px',
          width: '80px',
          backgroundColor: `${cell === 0 ? 'red' : cell === 1 ? 'rgb(76, 209, 55)' : null}`,
          cursor: 'pointer',
          margin: '0 auto',
        }}
      >
        {t(`common:magicnum.Status.${cell}`)}
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },

    headerOtherColumn: [
      {
        title: 'Дизайн',
        dataField: 'url',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Хэрэглэгч',
        dataField: 'created_by',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төрөл',
        dataField: 'type',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Статус',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Он сар өдөр',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үйлдэл',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Дэвсгэр зураг</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div onClick={() => List()} className='card-toolbar'>
              <a className='btn btn-sm btn-light-primary'>{t('common:search')}</a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='id'
                    type='number'
                    onChange={(e) => setId(e.target.value)}
                    placeholder='ID'
                    value={id}
                  />
                </div>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='name'
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Name'
                    value={name}
                  />
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setProvider(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='type'
                    value={type}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='BusinessCard'>{t('common:Background.Type.BusinessCard')}</option>
                    <option value='IdCard'>{t('common:Background.Type.IdCard')}</option>
                    <option value='Honor'>{t('common:Background.Type.Honor')}</option>
                  </select>
                </div>
                <div className='col '>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='0'>{t('common:magicnum.Status.0')}</option>
                    <option value='1'>{t('common:magicnum.Status.1')}</option>
                  </select>
                </div>
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS:clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
      <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Background</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total: {PTotal} Дэвсгэр зураг</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
            key={data.length}
          />
        </div>
      </div>
    </>
  )
}

export {NameCardBackground}
